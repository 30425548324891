import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {CustomerSite} from 'src/app/shared/models/entities/customer-site';
import {environment} from '../../../../../../../environments/environment';
import {Utils} from '../../../../../../shared/utils/utils';
import {VehicleCheckpoint} from '../../../../../../shared/models/entities/vehicle-checkpoint';
import {UserRoleType} from "../../../../../../shared/models/entities/user-role";
import {UserService} from "../../../../../../shared/services/api/user.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'laveo-customer-site-detail',
  templateUrl: './customer-site-detail.component.html',
  styleUrls: ['./customer-site-detail.component.scss']
})
export class CustomerSiteDetailComponent implements OnInit, OnDestroy {
  @Input() customerSite?: CustomerSite;

  bilanRseUrl = environment.api.url + '/bilan_rse/';
  vehicleCheckpointGroupedByCategory: {
    key: string;
    value: VehicleCheckpoint[];
  }[] = [];
  isAdmin = false;
  private subscriptions: Subscription[] = [];

  constructor(
    private readonly userService: UserService,
  ) {
  }

  ngOnInit(): void {
    this.loadRole();
    if (this.customerSite?.vehicleCheckpoints) {
      const grouped = Utils.groupBy(this.customerSite?.vehicleCheckpoints, d => d.category);
      this.vehicleCheckpointGroupedByCategory = Object.keys(grouped).map(key => ({
        key,
        value: grouped[key]
      }));
    }
  }

  ngOnDestroy(): void {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }

  getBillingAddress() {
    let txt = "";
    if (Utils.isNotBlank(this.customerSite?.billingRecipient)) {
      txt += this.customerSite?.billingRecipient + "\n";
    }
    if (Utils.isNotBlank(this.customerSite?.billingAddress1)) {
      txt += this.customerSite?.billingAddress1 + "\n";
    }
    if (Utils.isNotBlank(this.customerSite?.billingAddress2)) {
      txt += this.customerSite?.billingAddress2 + "\n";
    }
    if (Utils.isNotBlank(this.customerSite?.billingAddress2)) {
      txt += this.customerSite?.billingAddress2 + "\n";
    }
    if (Utils.isNotBlank(this.customerSite?.billingPostalCode)) {
      txt += this.customerSite?.billingPostalCode + " ";
    }
    if (Utils.isNotBlank(this.customerSite?.billingCity)) {
      txt += this.customerSite?.billingCity + " ";
    }
    if (Utils.isNotBlank(this.customerSite?.billingCountry)) {
      txt += this.customerSite?.billingCountry + " ";
    }
    return txt.trim().replaceAll('\n', '<br>');


  }

  private loadRole(): void {
    const roleSubscription = this.userService.currentRole.subscribe(role => {
      this.isAdmin = role.type === UserRoleType.admin;
    });

    if (roleSubscription) {
      this.subscriptions.push(roleSubscription);
    }
  }
}
