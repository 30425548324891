import { Type } from 'typeserializer';
import { Entity } from './entity';
import {PricingByCustomerSite} from "./pricing-by-customer-site";

export class BatchProcessFailed extends Entity {
  message: string;
  object: PricingByCustomerSite;
}

export class BatchProcessPricing extends Entity {
  /** Liste des erreurs d'import */
  @Type([BatchProcessFailed])
  processFailed: BatchProcessFailed[];

  /** Liste des véhicules ajoutés */
  @Type([PricingByCustomerSite])
  processOk: PricingByCustomerSite[];
}
