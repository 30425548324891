import { Type } from 'typeserializer';
import { CustomerSite } from './customer-site';
import { Entity } from './entity';
import {Customer} from "./customer";

export class PricingByCustomerSite extends Entity {
  @Type(CustomerSite)
  customerSite: CustomerSite;
  @Type(Customer)
  model: Customer;

}

export class PricingArray {
  @Type([PricingByCustomerSite])
  children: PricingByCustomerSite[];
}
