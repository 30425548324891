import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {EntityWrapperComponent} from '../../shared/entity-wrapper/entity-wrapper.component';
import {UserService} from 'src/app/shared/services/api/user.service';
import {UserRight} from 'src/app/shared/models/entities/user-right';
import {Title} from '@angular/platform-browser';
import {ProviderInvoiceOrder} from "../../../../shared/models/entities/provider-invoice-order";
import {ProviderInvoiceOrdersService} from "../../../../shared/services/api/provider-invoice-orders.service";

@Component({
  selector: 'laveo-provider-invoice-order',
  templateUrl: './provider-invoice-order.component.html',
  styleUrls: ['./provider-invoice-order.component.scss']
})
export class ProviderInvoiceOrderComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(EntityWrapperComponent) entityWrapperComponent: EntityWrapperComponent;

  isReady = false;
  providerInvoiceOrder: ProviderInvoiceOrder;
  editEnabled = false;
  deleteEnabled = false;

  private subscriptions: Subscription[] = [];

  constructor(
    public readonly providerInvoiceOrdersService: ProviderInvoiceOrdersService,
    private readonly userService: UserService,
    private readonly titleService: Title,
  ) {}

  get title(): string {
    const order = this.providerInvoiceOrder;
    console.log("order :", order);
    if (order) {
      return order.reference + " - " + order.structure?.name ;

    }

    if (order === null) {
      return 'Bon de commande inconnu';
    }

    return '';
  }

  get description(): string {
    const order = this.providerInvoiceOrder;
    return order?.reference ?? '';
  }

  ngOnInit(): void {
    this.loadRole();
  }

  ngAfterViewInit(): void {
    this.subscriptions.push(this.entityWrapperComponent?.entity
      .subscribe(entity => {
        this.providerInvoiceOrder = entity as ProviderInvoiceOrder;
        if (this.providerInvoiceOrder !== undefined) {
          this.isReady = true;
        }
        this.setTitle();
      }));
  }

  ngOnDestroy(): void {
    for (const s of this.subscriptions) {
      s.unsubscribe();
    }
  }

  private loadRole(): void {
    const roleSubscription = this.userService.currentRole.subscribe(role => {
      this.editEnabled = role.rights.providerInvoiceOrders.includes(UserRight.update);
      this.deleteEnabled = role.rights.providerInvoiceOrders.includes(UserRight.delete);
    });

    if (roleSubscription) {
      this.subscriptions.push(roleSubscription);
    }
  }

  private setTitle(): void {
    this.titleService.setTitle(`Lavéo - ${this.title}`);
  }
}
