import { Pipe, PipeTransform } from '@angular/core';
import {StatusInvoice} from "../models/entities/invoice-request";

@Pipe({
  name: 'invoiceStatusColor'
})
export class InvoiceStatusColorPipe implements PipeTransform {
  transform(status?: StatusInvoice): string {
    if (!status) {
      return 'default';
    }

    switch (status) {
      case StatusInvoice.validationRequired:
      case StatusInvoice.customerValidationRequired: {
        return 'warning';
      }
      case StatusInvoice.invoiced:{
        return 'success';
      }
      case StatusInvoice.paid:{
        return '#57bd6c';
      }
      case StatusInvoice.validated:
      case StatusInvoice.created: {
        return 'blue';
      }
      default: {
        return 'default';
      }
    }
  }
}
