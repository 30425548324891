<div class="component-wrapper"  [class.loading]="actionLoading" >
<div nz-row [nzGutter]="[48, 24]">

  <div nz-col nzSpan="24" class="invoiceStatus" *ngIf="providerOrder" [class]="providerOrder.status.toLowerCase()">
    <div class="invoiceStatusTitle">
      [ {{ providerOrder.status | providerInvoiceOrderStatusName }} ]
    </div>
    <div class="reason" *ngIf="providerOrder.comment">{{ providerOrder.comment }}</div>

    <div class="seeInvoice" *ngIf="providerOrder.pennylaneFileUrl"><a [href]="providerOrder.pennylaneFileUrl" target="_blank">Voir la facture au format PDF</a></div>

  </div>
  <div nz-col nzSpan="24" class="globalActions"
       *ngIf="providerOrder?.status === ProviderInvoiceOrderStatus.created ||
      providerOrder?.status === ProviderInvoiceOrderStatus.validated">
    <a href="javascript:void(0);"
      (click)="copyDetailsToClipboard()" nz-tooltip="Copier le bon de commande dans le presse-papier."><i nz-icon nzType="copy"  nzTheme="outline" ></i></a>
    <a [href]="getCsvDownloadUrl()" nz-tooltip="Télécharger au format CSV"><i nz-icon nzType="file-excel" nzTheme="outline" ></i></a>
    <a [href]="getPdfDownloadUrl()" nz-tooltip="Télécharger au format PDF"><i nz-icon nzType="file-pdf" nzTheme="outline" ></i></a>

  </div>

</div>

<div nz-row [nzGutter]="[48, 24]">

  <div nz-col nzSpan="10" nzOffset="14" *ngIf="providerOrder">
    <div>Le {{ providerOrder.updatedDate  | dfnsFormat:('dd/MM/yy') }}</div>
    <div *ngIf="providerOrder.structure">
    <div class="mt-16"><strong>{{ providerOrder.structure.name }}</strong></div>
    <div>{{ providerOrder.structure.address | nl2br }}</div>
    <div>{{ providerOrder.structure.postalCode }}</div>
    <div>{{ providerOrder.structure.city }}</div>
    </div>
  </div>
</div>

<div nz-row [nzGutter]="[48, 0]">

  <div nz-col nzSpan="24">
    <div><span nz-typography>Référence du bon de commande : <strong>{{ providerOrder?.reference | nullCoal }}</strong></span></div>
    <div *ngIf="providerOrder?.invoiceDate">Facturé le : <strong>{{ providerOrder?.invoiceDate | dfnsFormat:('dd/MM/yy')   }}</strong></div>
    <div *ngIf="providerOrder?.deadlineDate">Limite de paiement : <strong>{{ providerOrder?.deadlineDate | dfnsFormat:('dd/MM/yy')   }}</strong></div>

  </div>



</div>
<div nz-row [nzGutter]="[48, 0]">

  <div nz-col nzSpan="24" class="mt-24">


    <nz-table [nzData]="['']" [nzFrontPagination]="false">
      <thead>
      <tr>
        <th nzColumnKey="articleReference">Référence</th>
        <th nzColumnKey="articleLabel">Libellé</th>
        <th class="centered" nzColumnKey="priceUnitWithoutVat">Prix unitaire HT</th>
        <th class="centered" nzColumnKey="quantity">Quantité</th>
        <th class="centered" nzColumnKey="priceTotalWithoutVat">Prix total HT</th>
        <th nzColumnKey="actions"></th>
      </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let detail of providerOrder?.providerInvoiceOrderDetail">
          <tr [class]="detail.isOperationPointed? 'detail-pointed':''">

            <td>{{ detail.articleReference }}</td>
            <td>
              <div>{{ detail.articleLabel }}</div>
              <div><small>{{ detail.additionalDescription }}</small></div>
            </td>
            <td class="centered">{{ detail.priceUnitWithoutVat | currency: 'EUR' }}</td>
            <td class="centered">{{ detail.quantity }}</td>
            <td class="centered">{{ detail.priceTotalWithoutVat | currency: 'EUR' }}</td>
            <td class="actions">

              <div *ngIf="canDoActionOnInvoice(providerOrder)">

                <button nz-tooltip nzTooltipTitle="Supprimer la ligne" nz-button nzType="primary" class="ml-8"
                        (click)="pointOperation(detail)" *ngIf="isStructure">
                  <i nz-icon nzType="delete"></i>
                </button>
                <button nz-tooltip nzTooltipTitle="Supprimer la ligne" nz-button nzType="primary" class="ml-8"
                        (click)="removeInvoiceDetail(detail)" *ngIf="userCanDelete">
                  <i nz-icon nzType="delete"></i>
                </button>

              </div>
            </td>

          </tr>
        </ng-container>

      <tr class="last-line">

        <td colspan="4" class="total-title">Total</td>

        <td>{{ providerOrder?.totalAmountWithoutVat | currency: 'EUR' }}</td>

        <td class="actions">

        </td>

      </tr>
      </tbody>
    </nz-table>
  </div>

  <div nz-col nzSpan="24" class="mt-16 action-bottom"
       *ngIf="providerOrder?.status === ProviderInvoiceOrderStatus.created && isAdmin">
    <button nz-button nzType="primary" nzSize="large" (click)="validate()">{{ getValidationText() }}</button>
  </div>

</div>


  <div *ngIf="actionLoading" class="overlay">
    <div class="spinner"></div>
    <div class="loading-text">Veuillez patienter...</div>


  </div>
</div>
