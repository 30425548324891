import {Component, Input, OnInit} from '@angular/core';
import { Structure } from 'src/app/shared/models/entities/structure';
import {UserService} from "../../../../../../shared/services/api/user.service";
import {UserRoleType} from "../../../../../../shared/models/entities/user-role";

@Component({
  selector: 'laveo-structure-detail',
  templateUrl: './structure-detail.component.html',
  styleUrls: ['./structure-detail.component.scss']
})
export class StructureDetailComponent implements OnInit {
  @Input() structure?: Structure;
  isAdmin = false;

  constructor(
    private readonly userService: UserService
  ) {}

  ngOnInit(): void {
    this.loadRole();
  }

  private loadRole(): void {
    this.userService.currentRole.subscribe(role => {
      this.isAdmin = role.type === UserRoleType.admin;
    });

  }
  preparers(structure: Structure): string {
    if (structure?.preparersMetaData?.totalResults > 0) {
      return structure.preparersMetaData?.totalResults > 1 ? structure.preparersMetaData?.totalResults + ' préparateurs associés' : structure.preparersMetaData?.totalResults + ' préparateur associé';
    }
    return 'Aucun préparateur associé';
  }

  customerSites(structure: Structure): string {
    if (structure?.customerSitesMetaData?.totalResults > 0) {
      return structure.customerSitesMetaData?.totalResults > 1 ? structure.customerSitesMetaData?.totalResults + ' sites associés' : structure.customerSitesMetaData?.totalResults + ' site associé';
    }
    return 'Aucun site associé';
  }

}
