import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {SignalRService} from "../../../../shared/services/signalr.service";
import {Utils} from "../../../../shared/utils/utils";

@Component({
  selector: 'laveo-progress-notifier',

  templateUrl: './progress-notifier.component.html',
  styleUrl: './progress-notifier.component.scss'
})
export class ProgressNotifierComponent implements OnInit {

  @Input() title = "Statut";
  @Input() icon = "file-text";
  @Input() queue = "InvoicingProgress";
  progress = 0;
  trace:LogMessage[]  = [];

  constructor(private signalRService: SignalRService, private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.signalRService.startConnection();
    this.reset();
    this.signalRService.addProgressListener(this.queue, (message: string) => {
      // Convert to Json
      const json = JSON.parse(message);
      this.progress = Math.round(json.progress);
      if (Utils.isNotBlank(json.message))
      this.trace.push({"level": json.level, "message": json.message.trim()});
      // Trigger change detection
      this.cdr.detectChanges();
    });
  }

  public reset():void {
    this.trace = [];
    this.progress = 0;
  }
}
interface LogMessage {
  level: any;
  message: any;
}
