import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {NzCardModule} from 'ng-zorro-antd/card';
import {NzCheckboxModule} from 'ng-zorro-antd/checkbox';
import {NzEmptyModule} from 'ng-zorro-antd/empty';
import {NzFormModule} from 'ng-zorro-antd/form';
import {NzIconModule} from 'ng-zorro-antd/icon';
import {NzInputModule} from 'ng-zorro-antd/input';
import {NzResultModule} from 'ng-zorro-antd/result';
import {NzTableModule} from 'ng-zorro-antd/table';
import {MainSharedModule} from '../shared/main-shared.module';
import {SharedModule} from '../../../shared/shared.module';
import {NzRadioModule} from 'ng-zorro-antd/radio';
import {NzTreeModule} from 'ng-zorro-antd/tree';
import {NzDropDownModule} from 'ng-zorro-antd/dropdown';
import {NzDividerComponent} from "ng-zorro-antd/divider";
import {NzInputNumberComponent} from "ng-zorro-antd/input-number";
import {InvoicesComponent} from "./invoices.component";
import {FormatPipeModule} from "ngx-date-fns";
import {InvoiceComponent} from "./invoices/invoice.component";
import {InvoiceDetailComponent} from "./invoices/invoice-detail/invoice-detail.component";
import {InvoiceListComponent} from "./invoices/invoice-list/invoice-list.component";
import {NzTabComponent, NzTabDirective, NzTabSetComponent} from "ng-zorro-antd/tabs";
import {AdminPricingComponent} from "./admin-pricing/admin-pricing.component";
import {NzUploadComponent} from "ng-zorro-antd/upload";
import {NzModalComponent} from "ng-zorro-antd/modal";
import {NzTooltipDirective} from "ng-zorro-antd/tooltip";
import {NzTagComponent} from "ng-zorro-antd/tag";
import {NzSpinComponent} from "ng-zorro-antd/spin";
import {ListServicesModule} from "../../../shared/views/list-services/list-services.module";
import {FilterInvoicesComponent} from "./filter-invoices/filter-invoices.component";
import {NzOptionComponent, NzSelectComponent} from "ng-zorro-antd/select";
import {SelectActorModule} from "../../../shared/views/inputs/select-actor/select-actor.module";
import {NzDatePickerComponent, NzDatePickerModule, NzRangePickerComponent} from "ng-zorro-antd/date-picker";
import {NzTimePickerComponent} from "ng-zorro-antd/time-picker";
import {NzListComponent, NzListEmptyComponent, NzListItemComponent} from "ng-zorro-antd/list";
import {NzPaginationComponent} from "ng-zorro-antd/pagination";
import {NzTypographyComponent} from "ng-zorro-antd/typography";
import {RowServiceModule} from "../../../shared/views/row-service/row-service.module";
import {ProviderInvoiceOrdersModule} from "../provider-invoice-orders/provider-invoice-orders.module";
import { NgChartsModule } from 'ng2-charts';
import {StatisticsComponent} from "./statistics/statistics.component";


@NgModule({
  declarations: [
    InvoicesComponent,
    InvoiceComponent,
    InvoiceDetailComponent,
    InvoiceListComponent,
    AdminPricingComponent,
    FilterInvoicesComponent,
    StatisticsComponent
  ],
  imports: [
    CommonModule,
    MainSharedModule,
    SharedModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NzButtonModule,
    NzCheckboxModule,
    NzIconModule,
    NzCardModule,
    NzFormModule,
    NzTableModule,
    NzInputModule,
    NzResultModule,
    NzEmptyModule,
    NzRadioModule,
    NzTreeModule,
    NzDropDownModule,
    NzDividerComponent,
    NzInputNumberComponent,
    FormatPipeModule,
    NzTabComponent,
    NzTabDirective,
    NzTabSetComponent,
    NzUploadComponent,
    NzModalComponent,
    NzTooltipDirective,
    NzTagComponent,
    NzSpinComponent,
    ListServicesModule,
    NzSelectComponent,
    NzOptionComponent,
    SelectActorModule,
    NzRangePickerComponent,
    NzDatePickerComponent,
    NzTimePickerComponent,
    NzListComponent,
    NzListEmptyComponent,
    NzListItemComponent,
    NzPaginationComponent,
    NzTypographyComponent,
    RowServiceModule,
    ProviderInvoiceOrdersModule,
    NzDatePickerModule,
    NzButtonModule,
    NgChartsModule
  ],
  exports: [
    InvoicesComponent,
    AdminPricingComponent,
    FilterInvoicesComponent,
    StatisticsComponent
  ]
})
export class InvoicesModule {}
