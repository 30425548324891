
<div nz-row class="mb-16">
  <div>{{ description }}</div>
</div>


<div class="mb-16">
  <laveo-filter-invoices
    [originalList]="datas ? datas.data: []"
    (filtersChanged)="setFilters($event)"
    (checkedChanged)="reloadData()"
    [queryStringFilters]="queryStringFilters"
    [autofocus]="shouldAutofocusSearch"
    [checked]="checked">
  </laveo-filter-invoices>
</div>

<div nz-row>


  <nz-table nzShowSizeChanger
            [nzData]=" datas ? datas.data : []"
            [nzFrontPagination]="false"
            [nzLoading]="isLoading"
            [nzTotal]="datas ? datas.metadata.totalResults : 0"
            [nzPageSize]="limit"
            [nzPageIndex]="filters.page ? filters.page : 0"
            [nzNoResult]="emptyTemplate"
            (nzQueryParams)="setPage($event)"
            *ngIf="!error; else errorTemplate">
    <thead>
    <tr>
      <th nzWidth="60px" *ngIf="isAdmin">
        <label nz-checkbox [(ngModel)]="allChecked" [nzIndeterminate]="indeterminate" (ngModelChange)="onAllChecked($event)"></label>
      </th>
      <th nzColumnKey="updatedDate" [nzSortFn]="true">Date de modification</th>
      <th nzColumnKey="reference" [nzSortFn]="true" >Reference</th>
      <th nzColumnKey="customerSite" [nzSortFn]="true" *ngIf="userCanReadCustomerSites && showCustomerSite">Site Client</th>
      <th nzColumnKey="customer" [nzSortFn]="true" *ngIf="userCanReadClients">Client</th>
      <th nzColumnKey="totalAmountWithoutVat" [nzSortFn]="true">Total HT</th>
      <th nzColumnKey="totalAmount" [nzSortFn]="true" *ngIf="!isStructure" >Total TTC</th>
      <th nzColumnKey="status" [nzSortFn]="true" >Statut</th>
      <th nzColumnKey="actions" class="actions">Actions</th>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngFor="let invoiceRequest of datas?.data">
      <tr>
        <td [nzChecked]="isChecked(invoiceRequest)" (nzCheckedChange)="setChecked($event, invoiceRequest)"
            *ngIf="userCanDelete"></td>
        <td>{{ invoiceRequest.updatedDate  | dfnsFormat:('dd/MM/yy HH\'h\'mm') }}</td>
        <td>{{ invoiceRequest.reference }}</td>
        <td *ngIf="userCanReadCustomerSites && showCustomerSite">
          <div *ngIf="invoiceRequest.customerSite">
            <button nz-button
                    (click)="goToCustomerSite(invoiceRequest.customerSite)">{{ invoiceRequest.customerSite.name }}<i
              nz-icon nzType="right-square" nzTheme="outline"></i></button>
          </div>
        </td>
        <td *ngIf="userCanReadClients">
          <button nz-button (click)="goToCustomer(invoiceRequest.customer)">{{ invoiceRequest.customer.name }}<i nz-icon
                                                                                                                 nzType="right-square"
                                                                                                                 nzTheme="outline"></i>
          </button>
        </td>
        <td>{{ invoiceRequest.totalAmountWithoutVat | currency: 'EUR' }}</td>
        <td *ngIf="!isStructure" >{{ invoiceRequest.totalAmount | currency: 'EUR' }}</td>
        <td>

          <div nz-col class="statut">
            <nz-tag
              nzColor="{{ invoiceRequest.status | invoiceStatusColor }}"
              class="w-100-p"
              nz-tooltip
              [nzTooltipTitle]="invoiceRequest.status | invoiceStatusName">
              {{ invoiceRequest.status | invoiceStatusName:true }}
            </nz-tag>
          </div>

        </td>
        <td class="actions">
          <button nz-button class="ml-8" (click)="openPdf(invoiceRequest)" *ngIf="invoiceRequest.pennylaneFileUrl" >
            <i nz-icon nzType="file-pdf"></i>
          </button>
          <button nz-button class="ml-8" [routerLink]="['/', 'invoice', invoiceRequest.id]">
            <i nz-icon [nzType]="isEditable(invoiceRequest)?'edit':'eye'"></i>
          </button>
          <button nz-button class="ml-8" nzType="primary" (click)="delete(invoiceRequest)" *ngIf="isDeletable(invoiceRequest)">
            <i nz-icon nzType="delete"></i>

          </button>
          <button nz-button class="ml-8"  nz-tooltip="Valider la facture" (click)="validate(invoiceRequest)" *ngIf="isAdmin && invoiceRequest.status === StatusInvoice.created">
            <i nz-icon nzType="check"></i>
          </button>
          <button nz-button class="ml-8"  nz-tooltip="Marquer comme payée" (click)="markAsPaid(invoiceRequest)" *ngIf="isAdmin && invoiceRequest.status === StatusInvoice.invoiced">
            <i nz-icon nzType="check"></i>
          </button>
        </td>
      </tr>
    </ng-container>
    </tbody>
  </nz-table>


  <ng-template #errorTemplate>
    <nz-result nzTitle="Erreur de chargement" nzStatus="error"
               nzSubTitle="Une erreur est survenue lors du chargement de la liste des factures">
      <div nz-result-extra>
        <button nz-button nzType="primary" (click)="loadData()">
          Réessayer
          <i nz-icon nzType="reload"></i>
        </button>
      </div>
    </nz-result>
  </ng-template>
  <ng-template #emptyTemplate>
    <nz-empty
      nzNotFoundImage="simple"
      nzNotFoundContent="Aucune facture trouvée"
      [nzNotFoundFooter]="emptyFooter">
      <ng-template #emptyFooter>

        <div></div>
      </ng-template>
    </nz-empty>
  </ng-template>
</div>
