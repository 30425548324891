import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UserRole, UserRoleType} from "../../../../../shared/models/entities/user-role";
import {UserRight} from "../../../../../shared/models/entities/user-right";
import {UserService} from "../../../../../shared/services/api/user.service";
import {Title} from "@angular/platform-browser";
import {Subscription} from "rxjs";
import {NzModalService} from "ng-zorro-antd/modal";
import {ApiErrorMessageUtil} from "../../../../../shared/utils/api-error-message.util";
import {NzMessageService} from "ng-zorro-antd/message";
import {
  ProviderInvoiceOrder,
  ProviderInvoiceOrderStatus
} from "../../../../../shared/models/entities/provider-invoice-order";
import {ProviderInvoiceOrdersService} from "../../../../../shared/services/api/provider-invoice-orders.service";
import {ProviderInvoiceOrderDetail} from "../../../../../shared/models/entities/provider-invoice-order-detail";
import {environment} from "../../../../../../environments/environment";

@Component({
  selector: 'laveo-provider-invoice-order-detail',
  templateUrl: './provider-invoice-order-detail.component.html',
  styleUrls: ['./provider-invoice-order-detail.component.scss']
})
export class ProviderInvoiceOrderDetailComponent implements OnInit, OnDestroy {
  @Input() providerOrder?: ProviderInvoiceOrder;

  userCanEdit = false;
  userCanDelete = false;
  currentRole?: UserRole;
  actionLoading = false;
  isUpdating = false;
  isStructure = false;
  protected readonly ProviderInvoiceOrderStatus = ProviderInvoiceOrderStatus;
  protected isAdmin: boolean;
  private subscriptions: Subscription[] = [];

  constructor(
    private readonly userService: UserService,
    private readonly titleService: Title,
    private readonly modal: NzModalService,
    private readonly message: NzMessageService,
    private readonly providerInvoiceOrdersService: ProviderInvoiceOrdersService,
  ) {}


  ngOnInit(): void {
    this.setTitle();
    this.loadRole();
  }


  ngOnDestroy(): void {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }

  /*
   * Returns true if the order can be updated.
   * This means the order status is Created of ValidationRequired
   */
  canDoActionOnInvoice(order: ProviderInvoiceOrder | undefined) {
    return order?.status == ProviderInvoiceOrderStatus.created && this.isAdmin;
  }

  /**
   * Deletes a detail.
   * Open a warning because the order line need to be recreated manually.
   */
  removeInvoiceDetail(detail: ProviderInvoiceOrderDetail) {
    console.log("Delete order line :", detail);
    // TODO : add warning to tell the line need to be invoiced manually

    this.modal.confirm({
      nzTitle: 'Supprimer la ligne ' + detail.articleReference + ' ?',
      nzContent: `Êtes-vous sûr de vouloir supprimer la ligne  <b>${detail.articleReference}</b> ?<br /> ` +
        "Attention : cette opération est irréversible. Si vous supprimez la ligne, elle ne sera pas transmise à la structure et ne pourra être ajoutée automatiquement ultérieurement.<br /><br/> " +
        "Etes-vous sûr(e) de vouloir continuer ?",
      nzOkText: 'Supprimer',
      nzOkType: 'primary',
      nzCancelText: 'Annuler',
      nzOnOk: () => {
        this.actionLoading = true;

        this.providerInvoiceOrdersService.deleteInvoiceDetail(detail.id).subscribe({
          next: response => {
            console.log("Response", response);
            // If response is ok, remove line
            if (response.data) {
              this.providerOrder = response.data;
            }

            this.actionLoading = false;
          },
          error: error => {
            console.error(error);
            this.actionLoading = false;
            this.message.error(ApiErrorMessageUtil.getMessageFromError(error));
          }
        });


      }
    });
  }

  copyDetailsToClipboard(): void {
    if (this.providerOrder) {
      const details = this.providerOrder.providerInvoiceOrderDetail.map(detail =>
        `    - ${detail.articleLabel} - ${detail.additionalDescription} : ${detail.priceTotalWithoutVat}€ HT`
      ).join('\n');

      const total = `Tarif total : ${this.providerOrder.totalAmountWithoutVat}€ HT`;

      const textToCopy = `Prestations du bon de commande ${this.providerOrder.reference}\n${details}\n${total}`;

      navigator.clipboard.writeText(textToCopy).then(() => {
        this.message.info("Le contenu du bon de commande a été copié dans le presse-papier. Vous pouvez utiliser ce contenu dans votre facture à destination de Lavéo.")

      }).catch(error => {
        console.error('Could not copy text to clipboard:', error);
      });
    }
  }

  validate() {

    if (!this.providerOrder) {
      // Error

      this.modal.create({
        nzTitle: 'Erreur',
        nzContent: "Il n'y a pas de bon de commande chargé. Vous ne pouvez pas valider le bon de commande",
        nzFooter: [
          {
            label: 'OK',
            onClick: () => this.modal.closeAll()
          }
        ]
      });

    }
    // Todo check if order empty
    this.modal.confirm({
      nzTitle: 'Valider le bon de commande ' + this.providerOrder?.reference + ' ?',
      nzContent: `Êtes-vous sûr de vouloir valider le bon de commande <b>${this.providerOrder?.reference}</b> ?<br /> ` +
        "Attention : une fois le bon de commande validé, il va être envoyé à votre structure.<br /><br/> " +
        "Etes-vous vraiment sûr(e) de vouloir continuer ?",
      nzOkText: 'Valider le bon de commande',
      nzOkType: 'primary',
      nzCancelText: 'Annuler',
      nzOnOk: () => {
        this.actionLoading = true;

        this.providerInvoiceOrdersService.validateInvoice(this.providerOrder!.id).subscribe({
          next: response => {
            console.log("Response", response);
            // If response is ok, remove line
            if (response.data) {
              this.providerOrder = response.data;
            }
            // Check the errors

            this.scrollToTop();
            this.actionLoading = false;
          },
          error: error => {
            console.error("Error:", error);
            // Check if the error is a GraphQL error
            this.message.error(ApiErrorMessageUtil.getMessageFromError(error));
            this.scrollToTop();
            this.actionLoading = false;
          }
        });


      }
    });
  }


  getValidationText() {

    if (this.providerOrder?.status == ProviderInvoiceOrderStatus.created) {
      return "Valider";
    }

  }

  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  pointOperation(detail: ProviderInvoiceOrderDetail) {
    console.log("TODO Point operation for", detail);
  }

  private loadRole(): void {
    const roleSubscription = this.userService.currentRole.subscribe(role => {
      this.currentRole = role;
      this.isAdmin = role.type === UserRoleType.admin;
      this.isStructure = role.type === (UserRoleType.structure || UserRoleType.structureRead);

      this.userCanEdit = this.isAdmin || role.rights.providerInvoiceOrders.includes(UserRight.update);
      this.userCanDelete = this.isAdmin || role.rights.providerInvoiceOrders.includes(UserRight.delete);
    });

    if (roleSubscription) {
      this.subscriptions.push(roleSubscription);
    }
  }

  private setTitle(): void {
    this.titleService.setTitle('Lavéo - Détail du bon de commande ' + this.providerOrder?.reference);
  }

  getCsvDownloadUrl() {
    return  environment.api.url + '/api/provider/' + this.providerOrder?.id + "/download-csv";
  }

  getPdfDownloadUrl() {
    return  environment.api.url + '/api/provider/' + this.providerOrder?.id + "/download-pdf";

  }
}
