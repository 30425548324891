export enum UserRight {
  create = 'CREATE',
  read = 'READ',
  update = 'UPDATE',
  delete = 'DELETE'
}

export enum UserRightCategory {
  services = 'services',
  users = 'users',
  vehicles = 'vehicles',
  params = 'params',
  customers = 'customers',
  customerSites = 'customerSites',
  preparers = 'preparers',
  structures = 'structures',
  invoiceRequests = 'invoiceRequests',
  providerInvoiceOrders = 'providerInvoiceOrders'
}
