<form nz-form [formGroup]="form" nzLayout="vertical" (keydown.enter)="$event.preventDefault()">
  <div nz-row [nzGutter]="[24, 0]">
    <div nz-col nzSpan="24">
      <div nz-row [nzGutter]="24">
        <div nz-col nzSpan="6">
          <nz-form-item>
            <nz-form-label>
              <ng-container *ngIf="structure?.logo; else logoTitle">Nouveau Logo</ng-container>
              <ng-template #logoTitle>Logo</ng-template>
            </nz-form-label>
            <nz-form-control>
              <file-pond [options]="fileUploadOptions" (onpreparefile)="addLogo($event)"
                         (onremovefile)="removeLogo()"></file-pond>
            </nz-form-control>
            <span class="hint">Taille max. {{ this.fileUploadOptions.maxFileSize }}</span>
          </nz-form-item>
        </div>
        <div nz-col nzSpan="6" *ngIf="structure?.logo">
          <p>Logo actuel</p>
          <img class="current-logo" [src]="structure?.logo?.urlWithDomain" alt="Logo"/>
        </div>
      </div>
    </div>

    <div nz-col nzSpan="24">
      <div nz-row [nzGutter]="[24, 0]">
        <div nz-col nzFlex="0 0 auto" *ngIf="this.structure">
          <nz-form-item>
            <nz-form-label nzFor="active">Statut</nz-form-label>
            <nz-form-control>
              <nz-radio-group formControlName="active" nzButtonStyle="solid" [nzSize]="'large'">
                <label nz-radio-button [nzValue]="true">Activé</label>
                <label nz-radio-button [nzValue]="false">Désactivé</label>
              </nz-radio-group>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col nzFlex="1">
          <nz-form-item>
            <nz-form-label nzFor="name">Nom</nz-form-label>
            <nz-form-control [nzErrorTip]="nameError">
              <input formControlName="name" nz-input [nzSize]="'large'" placeholder="Nom"/>
            </nz-form-control>
            <ng-template #nameError let-control>
              <ng-container *ngIf="control.hasError('required')">Le nom de la structure est requis</ng-container>
            </ng-template>
          </nz-form-item>
        </div>

        <div nz-col nzFlex="1">
          <nz-form-item>
            <nz-form-label nzFor="name2">Raison Sociale</nz-form-label>
            <nz-form-control [nzErrorTip]="rsError">
              <input formControlName="name2" nz-input [nzSize]="'large'" placeholder="Raison sociale"/>
            </nz-form-control>
            <ng-template #rsError let-control>
              <ng-container *ngIf="control.hasError('required')">La raison sociale de la structure est requise
              </ng-container>
            </ng-template>
          </nz-form-item>
        </div>
      </div>
    </div>

    <div nz-col [nzXs]="24" *ngIf="googleMapsUtil.loaded | async">
      <nz-form-item>
        <nz-form-label nzFor="fullAddress">Adresse</nz-form-label>
        <nz-form-control>
          <input
            formControlName="fullAddress"
            nz-input
            nzSize="large"
            placeholder="Adresse"
            ngx-gp-autocomplete
            (onAddressChange)="onFullAddressChange($event)"
          />
        </nz-form-control>
      </nz-form-item>
    </div>

    <div nz-col [nzXs]="24">
      <laveo-inputs-contacts [controlArray]="contactFormArray"
                             [showNotificationsCheckbox]="true"></laveo-inputs-contacts>
    </div>

    <div nz-col [nzXs]="24" class="mt-16">
      <div class="detail">
        <span class="detail-content" nz-typography nzType="secondary">Préparateurs</span>
        <span class="detail-title" nz-typography>Pour ajouter/enlever des préparateurs à ce client, utilisez l'administration des préparateurs.</span>
        <span class="detail-title" *ngIf="structure">
          <button nz-button nzSize="large" [routerLink]="['/', 'admin', 'preparers']"
                  [queryParams]="{ s: structure.name }" [disabled]="structure.preparersMetaData.totalResults === 0">
            {{ preparers(structure) }}<i nz-icon nzType="right-square"
                                         *ngIf="(structure.preparersMetaData?.totalResults ? structure.preparersMetaData.totalResults : 0) > 0"></i>
          </button>
        </span>
      </div>
    </div>


    <div nz-col [nzXs]="24">
      <div class="detail">
        <span class="detail-content" nz-typography nzType="secondary">Sites clients</span>
        <span class="detail-title" nz-typography>Pour ajouter/enlever des sites client à ce client, utilisez l'administration des sites clients.</span>
        <span class="detail-title" *ngIf="structure">
          <button nz-button nzSize="large" [routerLink]="['/', 'admin', 'customer-sites']"
                  [queryParams]="{ s: structure.name }" [disabled]="structure.customerSitesMetaData.totalResults === 0">
            {{ customerSites(structure) }}<i nz-icon nzType="right-square"
                                             *ngIf="(structure.customerSitesMetaData?.totalResults ? structure.customerSitesMetaData.totalResults : 0) > 0"></i>
          </button>
        </span>
      </div>
    </div>


    <div nz-col nzSpan="24">
      <laveo-divider title="Facturation" icon="euro"></laveo-divider>

      <div nz-row [nzGutter]="[24, 0]">
        <div nz-col [nzXs]="12" [nzSm]="12">
          <nz-form-item>
            <nz-form-label nzFor="hasVat">TVA Applicable</nz-form-label>
            <nz-form-control>
              <nz-radio-group formControlName="hasVat" nzButtonStyle="solid" [nzSize]="'large'">
                <label nz-radio-button [nzValue]="true">Oui</label>
                <label nz-radio-button [nzValue]="false">Non</label>
              </nz-radio-group>
            </nz-form-control>
          </nz-form-item>

        </div>

        <div nz-col [nzXs]="12" [nzSm]="12">
          <nz-form-item>
            <nz-form-label nzFor="isAutoInvoiceEnabled">Auto-Facturation activée</nz-form-label>
            <nz-form-control>
              <nz-radio-group formControlName="isAutoInvoiceEnabled" nzButtonStyle="solid" [nzSize]="'large'">
                <label nz-radio-button [nzValue]="true">Oui</label>
                <label nz-radio-button [nzValue]="false">Non</label>
              </nz-radio-group>
            </nz-form-control>
          </nz-form-item>

        </div>

        <div nz-col [nzSm]="12">
          <nz-form-item>
            <nz-form-label nzFor="legalStatus">SIRET</nz-form-label>
            <nz-form-control>
              <input
                formControlName="registrationId"
                nz-input
                nzSize="large"
                placeholder="12345678901234"
                maxlength="16"/>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSm]="12">
          <nz-form-item>
            <nz-form-label nzFor="legalStatus">Numéro de TVA Intracommunautaire</nz-form-label>
            <nz-form-control>
              <input
                formControlName="vatNumber"
                nz-input
                nzSize="large"
                placeholder="FR12345678901"
                maxlength="18"/>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col [nzSm]="12">
          <nz-form-item>
            <nz-form-label nzFor="legalStatus">Forme Juridique</nz-form-label>
            <nz-form-control>
              <input
                formControlName="legalStatus"
                nz-input
                nzSize="large"
                placeholder="SARL"
                maxlength="128"/>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSm]="6">
          <nz-form-item>
            <nz-form-label nzFor="shareCapital">Capital</nz-form-label>
            <nz-form-control>
              <nz-input-group nzSuffix="€"
                              nzSize="large">
                <input
                  formControlName="shareCapital"
                  nz-input
                  nzSize="large"
                  placeholder="10000"
                  type="number"
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSm]="6">
          <nz-form-item>
            <nz-form-label nzFor="nafCode">Code NAF</nz-form-label>
            <nz-form-control>
              <input
                formControlName="nafCode"
                nz-input
                nzSize="large"
                placeholder="45.20A"
                maxlength="10"
              />
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col [nzSm]="12">
          <nz-form-item>
            <nz-form-label nzFor="rcsNumber">Numéro RCS</nz-form-label>
            <nz-form-control>
              <input
                formControlName="rcsNumber"
                nz-input
                nzSize="large"
                placeholder="12345678901234"
                maxlength="16"
              />
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSm]="12">
          <nz-form-item>
            <nz-form-label nzFor="rcsCity">Ville du greffe pour le RCS</nz-form-label>
            <nz-form-control>
              <input
                formControlName="rcsCity"
                nz-input
                nzSize="large"
                placeholder="Marseille"
                maxlength="128"
              />
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col [nzSm]="24">
          <nz-form-item>
            <nz-form-label nzFor="otherInvoiceInfo">Autre information à indiquer dans la facture</nz-form-label>
            <nz-form-control>
              <input
                formControlName="otherInvoiceInfo"
                nz-input
                nzSize="large"
                placeholder=""
                maxlength="128"
              />
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </div>
  </div>
</form>
