import { NgModule } from '@angular/core';
import { DashboardModule } from './dashboard/dashboard.module';
import { MainComponent } from './main.component';
import { RouterModule } from '@angular/router';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from '../../shared/shared.module';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzCardModule } from 'ng-zorro-antd/card';
import { MainSharedModule } from './shared/main-shared.module';
import { AdminModule } from './admin/admin.module';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { ServicesModule } from './services/services.module';
import { ServiceModule } from './service/service.module';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { AccountModule } from './account/account.module';
import { ReportingModule } from './reporting/reporting.module';
import { NzIconModule } from 'ng-zorro-antd/icon';

@NgModule({
    declarations: [
        MainComponent,
    ],
    imports: [
        SharedModule,
        RouterModule,
        MainSharedModule,
        AdminModule,
        AccountModule,
        DashboardModule,
        ReportingModule,
        ServicesModule,
        ServiceModule,
        NzCardModule,
        NzDrawerModule,
        NzLayoutModule,
        NzGridModule,
        NzMenuModule,
        NzIconModule,
        NzButtonModule,
        NzAvatarModule,
        NzDropDownModule,
        NzBadgeModule,
        NzModalModule,
        FormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
    ]
})
export class MainModule {}
