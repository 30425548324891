<nz-card [nzBordered]="false" [nzTitle]="cardTitle">
  <ng-template #cardTitle>
    <laveo-card-header
      [title]="category | rightName:currentRole?.type"
      [backButtonEnabled]="true"
      (backButtonClick)="goToAdmin()">
    </laveo-card-header>
  </ng-template>


  <div *ngIf="isAllowed">
    <nz-tabset>
      <nz-tab [nzTitle]="invoiceTitle">
        <ng-template #invoiceTitle>
          <i nz-icon nzType="euro"></i>
          Bons de commande
        </ng-template>
        <ng-template nz-tab>
          <laveo-provider-invoice-order-list></laveo-provider-invoice-order-list>
        </ng-template>
      </nz-tab>


    </nz-tabset>
  </div>
</nz-card>
