import {Deserializer, Type} from 'typeserializer';
import {CustomerSite} from './customer-site';
import {Entity} from './entity';
import {Customer} from "./customer";
import {DateConverter} from "../../type-converters/date.converter";
import {InvoiceRequestSection} from "./invoice-request-section";

export class InvoiceRequest extends Entity {

  @Type(CustomerSite)
  customerSite: CustomerSite;
  reference: string
  orderReference: string | undefined
  status: StatusInvoice
  statusReason: string
  totalAmount: number
  totalAmountWithoutVat: number
  invoiceRequestSection: InvoiceRequestSection[]

  pennylaneFileUrl: string
  pennylaneInvoiceNumber: string
  pennylanePublicUrl: string

  // FOR BILLING ADDRESS
  companyName: string
  address: string
  postalCode: string
  city: string
  country: string

  @Type(Date)
  @Deserializer(DateConverter.deserialize)
  updatedDate: Date


  @Type(Customer)
  customer: Customer;


  getStatusText() {
    return "";
  }


  equals(other: InvoiceRequest): boolean {
    if (!other) {
      return false;
    }
    return this.id === other.id ;
  }
}

export class InvoiceRequestArray {
  @Type([InvoiceRequest])
  children: InvoiceRequest[];
}

export enum StatusInvoice {
  created = 'CREATED',
  customerValidationRequired = 'CUSTOMER_VALIDATION_REQUIRED',
  validationRequired = 'VALIDATION_REQUIRED',
  validated = 'VALIDATED',
  invoiced = 'INVOICED',
  paid = 'PAID'

}

export enum FinishingServiceGlobal {
  classic = 'CLASSIC',
  premium = 'PREMIUM'

}
