<nz-card [nzBordered]="false" [nzTitle]="cardTitle">
  <ng-template #cardTitle>
    <div class="frame-zone">

      <laveo-divider title="Import / Export des tarifs et des paramètres" icon="file-excel"></laveo-divider>

      <div class="header">
        <nz-upload [(nzFileList)]="importFileList" (nzFileListChange)="handleChange($event)" [nzCustomRequest]="import"
                   [nzAccept]="'.xlsx,.xls'" *ngIf="this.userIsAdmin">
          <button
            nz-button
            nzType="primary"
            nzSize="large" (click)="resetAllProgresses()"
          ><i nz-icon nzType="upload"></i>Importer des tarifs (.xlsx)
          </button>
        </nz-upload>
        <button
          nz-button
          nzType="primary"
          nzSize="large"
          (click)="exportExcel()"
          class="ml-8"
          *ngIf="this.userIsAdmin"><i nz-icon nzType="download"></i>Télécharger les tarifs actuels
        </button>

      </div>
    </div>

    <div class="import-detail-zone">

      <laveo-progress-notifier #importNotifier title="Détail de l'import Excel" queue="ExcelImportProgress"/>
      <laveo-progress-notifier #exportNotifier title="Détail de l'export Excel" queue="ExcelExportProgress"/>
    </div>

    <div class="frame-zone">
      <laveo-divider title="Génération des factures clients" icon="euro"></laveo-divider>

      <form nz-form [formGroup]="generateForm" nzLayout="vertical">

        <div nz-col [nzSm]="24" [nzMd]="12" [nzXl]="5">
          <nz-form-item>
            <nz-form-label nzFor="dates">Période</nz-form-label>
            <nz-form-control>
              <nz-range-picker id="dates" class="w-100-p" nzSize="large" nzFormat="dd/MM/yyyy" formControlName="dates"
                               [nzRanges]="dateRanges"></nz-range-picker>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSm]="24" [nzMd]="12" [nzXl]="5">
          <button
            nz-button
            nzType="primary"
            nzSize="large"
            (click)="launchInvoicing()"
            *ngIf="this.userIsAdmin"><i nz-icon [nzType]="!actionLoading ? 'file-sync' : 'loading'"></i>Générer les
            factures
          </button>
        </div>

      </form>

    </div>

    <div class="import-detail-zone">
      <laveo-progress-notifier #invoiceNotifier title="Détail de la génération des factures" queue="InvoicingProgress"/>
    </div>

    <div class="frame-zone">
      <laveo-divider title="Génération des bons de commande / auto-factures fournisseurs" icon="euro"></laveo-divider>

      <form nz-form [formGroup]="generateForm" nzLayout="vertical">

        <div nz-col [nzSm]="24" [nzMd]="12" [nzXl]="5">
          <nz-form-item>
            <nz-form-label nzFor="datesProvider">Période</nz-form-label>
            <nz-form-control>
              <nz-range-picker id="datesProvider" class="w-100-p" nzSize="large" nzFormat="dd/MM/yyyy" formControlName="dates"
                               [nzRanges]="dateRanges"></nz-range-picker>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSm]="24" [nzMd]="12" [nzXl]="5">
          <button
            nz-button
            nzType="primary"
            nzSize="large"
            (click)="launchProviderInvoiceOrders()"
            *ngIf="this.userIsAdmin"><i nz-icon [nzType]="!actionLoading ? 'file-sync' : 'loading'"></i>Générer les
            bons de commande / auto-factures
          </button>
        </div>

      </form>

    </div>
    <div class="import-detail-zone">
      <laveo-progress-notifier #providerOrderInvoiceNotifier title="Détail de la génération des bons de commande" queue="ProviderInvoiceOrderProgress"/>
    </div>

    <nz-modal
      [(nzVisible)]="importErrorModalVisible"
      (nzOnOk)="closeImportErrorModal()"
      (nzOnCancel)="closeImportErrorModal()"
      nzTitle="Erreur d'import pour {{ importErrors?.length }} tarifs">
      <ng-container *nzModalContent>
        <ul>
          <li *ngFor="let error of importErrors!"><b>{{ error.object.customerSite.name }}</b> : {{ error.message }}</li>
        </ul>
      </ng-container>
    </nz-modal>
  </ng-template>
</nz-card>
