<nz-card [nzBordered]="false" [nzTitle]="cardTitle">
  <ng-template #cardTitle>
    <laveo-card-header
      title="Gestion des utilisateurs"
      buttonTitle="Ajouter un utilisateur"
      buttonIcon="plus"
      [description]="description"
      [backButtonEnabled]="true"
      (backButtonClick)="goToAdmin()"
      (buttonClick)="add()"
      [isLoading]="isLoading"
    ></laveo-card-header>
  </ng-template>
  <div nz-row [nzGutter]="[16, 16]">
    <form nz-form [formGroup]="searchForm">
      <div nz-row [nzGutter]="8" [nzAlign]="'middle'">
        <div nz-col *ngIf="checked.length > 0 && userCanDelete">
          <button nz-button nzType="default" nzSize="large" nz-dropdown [nzDropdownMenu]="actionMenu">
            {{ checked.length }} {{ checked.length > 1 ? 'utilisateurs séléctionnés' : 'utilisateur séléctionné' }}
            <i nz-icon nzType="down"></i>
          </button>
          <nz-dropdown-menu #actionMenu="nzDropdownMenu">
            <ul nz-menu nzSelectable>
              <!-- <li nz-menu-item (click)="copyChecked()">
                <i nz-icon nzType="copy"></i>
                Dupliquer
              </li> -->
              <li nz-menu-item nzDanger (click)="deleteChecked()">
                <i nz-icon nzType="delete"></i>
                Désactiver
              </li>
              <li nz-menu-divider></li>
              <li nz-menu-item (click)="checked.splice(0, checked.length)">
                <i nz-icon nzType="minus-square"></i>
                Tout déséléctionner
              </li>
            </ul>
          </nz-dropdown-menu>
        </div>
        <div nz-col nzFlex="1 1 auto">
          <nz-input-group [nzPrefix]="prefixIconSearch" nzSize="large">
            <ng-template #prefixIconSearch>
              <i nz-icon nzType="search"></i>
            </ng-template>
            <input type="text" nz-input placeholder="Rechercher un utilisateur" formControlName="search" />
          </nz-input-group>
        </div>
        <div nz-col>
          <nz-form-control>
            <nz-select
              id="roles"
              nzSize="large"
              nzMode="multiple"
              nzPlaceHolder="Tous les rôles"
              [nzShowArrow]="true"
              [nzShowSearch]="true"
              [nzAllowClear]="true"
              formControlName="roles"
              [style.minWidth]="'200px'">
              <nz-option
                *ngFor="let role of allRoles"
                [nzLabel]="role | roleName"
                [nzValue]="role">
              </nz-option>
            </nz-select>
          </nz-form-control>
        </div>
      </div>
    </form>
    <nz-table
      nzShowSizeChanger
      [nzData]="users ? users.data : []"
      [nzFrontPagination]="false"
      [nzLoading]="isLoading"
      [nzTotal]="users ? users.metadata.totalResults : 0"
      [nzPageSize]="limit"
      [nzPageIndex]="users ? users.metadata.currentPage : 1"
      [nzNoResult]="emptyTemplate"
      (nzQueryParams)="setPage($event)"
      *ngIf="!error; else errorTemplate">
      <thead>
        <tr>
          <th nzWidth="60px" *ngIf="userCanDelete"></th>
          <th nzColumnKey="active" [nzSortFn]="true" nzWidth="60px">Actif</th>
          <th nzColumnKey="name" [nzSortFn]="true">Nom</th>
          <th nzColumnKey="mail" [nzSortFn]="true">Email</th>
          <th nzColumnKey="roles">Rôles</th>
          <th nzColumnKey="actions" class="actions">Actions</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let user of users?.data">
          <tr *ngIf="user.id === currentUser?.id; else otherUsers">
            <td class="tc"><i nz-icon nzType="user"></i></td>
            <td><label nz-checkbox nzDisabled [ngModel]="true"></label></td>
            <td>{{ user.name }}</td>
            <td><a href="mailto:{{ user.mailValidation ?? user.mail| lowercase }}">{{ user.mailValidation ?? user.mail| lowercase }}</a></td>
            <td>
              <button nz-button nzType="default" (click)="toggleExpanded(user.id)" [disabled]="user.roles.length === 0">
                <i nz-icon [nzType]="expanded.includes(user.id) ? 'minus-square' : 'plus-square'"></i>
                {{ user.roles.length > 0 ? (user.roles.length > 1 ? user.roles.length + ' rôles associés' : '1 rôle associé') : 'Aucun rôle associcé à l\'utilisateur' }}
              </button>
            </td>
            <td class="actions">
              <button nz-button [disabled]="actionLoading" [routerLink]="['/', 'account']">
                <i nz-icon nzType="eye"></i>
                Détails
              </button>
            </td>
          </tr>
          <ng-template #otherUsers>
            <tr>
              <td [nzChecked]="checked.includes(user.id)" (nzCheckedChange)="setChecked($event, user.id)" *ngIf="userCanDelete"></td>
              <td><label nz-checkbox nzDisabled [(ngModel)]="user.active"></label></td>
              <td>{{ user.name }}</td>
              <td><a href="mailto:{{ user.mail | lowercase }}">{{ user.mail | lowercase }}</a></td>
              <td>
                <button nz-button nzType="default" (click)="toggleExpanded(user.id)" [disabled]="user.roles.length === 0">
                  <i nz-icon [nzType]="expanded.includes(user.id) ? 'minus-square' : 'plus-square'"></i>
                  {{ user.roles.length > 0 ? (user.roles.length > 1 ? user.roles.length + ' rôles associés' : '1 rôle associé') : 'Aucun rôle associcé à l\'utilisateur' }}
                </button>
              </td>
              <td class="actions">
                <button nz-button class="mr-8" [disabled]="actionLoading" [routerLink]="['/', 'admin', 'users', user.id]">
                  <i nz-icon nzType="eye"></i>
                  Détails
                </button>
                <button nz-button class="mr-8" (click)="edit(user)" [disabled]="actionLoading" *ngIf="userCanEdit">Modifier</button>
                <button nz-button nzType="primary" [disabled]="actionLoading" (click)="delete(user)" *ngIf="this.user.active && userCanDelete">
                  <i nz-icon nzType="delete"></i>
                </button>
                <button nz-tooltip nzTooltipTitle="Renvoyer le mail d'activation" nz-button nzType="primary" class="ml-8"
                        [disabled]="actionLoading" (click)="sendMailActivation(user)" *ngIf="!this.user.activatedByUser">
                  <i nz-icon nzType="mail"></i>
                </button>
              </td>
            </tr>
          </ng-template>
          <tr *ngIf="expanded.includes(user.id)">
            <td colspan="6">
              <nz-table [nzData]="user.roles" [nzShowPagination]="false">
                <thead>
                  <tr>
                    <th nzWidth="90px"></th>
                    <th nzWidth="300px">Entité</th>
                    <th>Rôle</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let role of user.roles">
                    <td></td>
                    <td *ngIf="role.type === 'SUPER_ADMIN'; else roleButton">{{ role.actor.name }}</td>
                    <ng-template #roleButton>
                      <td><button nz-button (click)="goToActor(role.actor, role.type)">{{ role.actor.name }}<i nz-icon nzType="right-square"></i></button></td>
                    </ng-template>
                    <td>{{ role.type | roleName }}</td>
                  </tr>
                </tbody>
              </nz-table>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </nz-table>
    <ng-template #errorTemplate>
      <nz-result
        nzTitle="Erreur de chargement"
        nzStatus="error"
        nzSubTitle="Une erreur est survenue lors du chargement de la liste des utilisateurs">
        <div nz-result-extra>
          <button nz-button nzType="primary" (click)="loadData()">
            Réessayer
            <i nz-icon nzType="reload"></i>
          </button>
        </div>
      </nz-result>
    </ng-template>
    <ng-template #emptyTemplate>
      <nz-empty
        nzNotFoundImage="simple"
        nzNotFoundContent="Il n'y a pas encore d'utilisateur. Commencez par en ajouter un en utilisant le bouton ci-dessous."
        [nzNotFoundFooter="emptyFooter">
        <ng-template #emptyFooter>
          <button nz-button nzType="primary" (click)="add()">
            <i nz-icon nzType="plus"></i>
            Ajouter un utilisateur
          </button>
        </ng-template>
      </nz-empty>
    </ng-template>
  </div>
</nz-card>
