import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared.module';
import { RowServiceModule } from '../row-service/row-service.module';
import { RouterModule } from '@angular/router';
import { ListContactsComponent } from './list-contacts.component';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { FormsModule } from '@angular/forms';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import {MainSharedModule} from "../../../pages/main/shared/main-shared.module";

@NgModule({
  declarations: [
    ListContactsComponent,
  ],
    imports: [
        CommonModule,
        SharedModule,
        RouterModule,
        FormsModule,
        RowServiceModule,
        NzTableModule,
        NzEmptyModule,
        NzTypographyModule,
        NzGridModule,
        NzCheckboxModule,
        NzToolTipModule,
        MainSharedModule
    ],
  exports: [
    ListContactsComponent
  ]
})
export class ListContactsModule {}
