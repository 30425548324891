import { Pipe, PipeTransform } from '@angular/core';
import {ProviderInvoiceOrderStatus} from "../models/entities/provider-invoice-order";

@Pipe({
  name: 'providerInvoiceOrderStatusName'
})
export class ProviderInvoiceOrderStatusNamePipe implements PipeTransform {



  transform(status?: ProviderInvoiceOrderStatus): string | undefined {
    if (!status) {
      return;
    }

    switch (status) {
      case ProviderInvoiceOrderStatus.invoiced: {
        return 'Facturé';
      }
      case ProviderInvoiceOrderStatus.validated: {
        return 'Validé';
      }
      case ProviderInvoiceOrderStatus.created: {
        return 'Créé';
      }
      case ProviderInvoiceOrderStatus.paid: {
        return 'Payé';
      }

      default: {
        return;
      }
    }
  }
}
