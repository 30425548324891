<nz-card [nzBordered]="false" [nzTitle]="cardTitle">
  <ng-template #cardTitle>
    <laveo-card-header
      title="Gestion des structures"
      [buttonTitle]="this.userCanAdd ? 'Ajouter une structure' : undefined"
      [buttonIcon]="this.userCanAdd ? 'plus' : undefined"
      [description]="description"
      [backButtonEnabled]="true"
      (backButtonClick)="goToAdmin()"
      (buttonClick)="add()"
      [isLoading]="isLoading">
    </laveo-card-header>
  </ng-template>
  <div nz-row [nzGutter]="[16, 16]">
    <form nz-form [formGroup]="searchForm">
      <div nz-row [nzGutter]="8" [nzAlign]="'middle'">
        <div nz-col *ngIf="checked.length > 0 && userCanDelete">
          <button nz-button nzType="default" nzSize="large" nz-dropdown [nzDropdownMenu]="actionMenu">
            {{ checked.length }} {{ checked.length > 1 ? 'structures séléctionnés' : 'structure séléctionné' }}
            <i nz-icon nzType="down"></i>
          </button>
          <nz-dropdown-menu #actionMenu="nzDropdownMenu">
            <ul nz-menu nzSelectable>
              <!-- <li nz-menu-item (click)="copyChecked()">
                <i nz-icon nzType="copy"></i>
                Dupliquer
              </li> -->
              <li nz-menu-item nzDanger (click)="deleteChecked()">
                <i nz-icon nzType="delete"></i>
                Désactiver
              </li>
              <li nz-menu-divider></li>
              <li nz-menu-item (click)="checked.splice(0, checked.length)">
                <i nz-icon nzType="minus-square"></i>
                Tout déséléctionner
              </li>
            </ul>
          </nz-dropdown-menu>
        </div>
        <div nz-col nzFlex="1 1 auto">
          <nz-input-group [nzPrefix]="prefixIconSearch" nzSize="large">
            <ng-template #prefixIconSearch>
              <i nz-icon nzType="search"></i>
            </ng-template>
            <input type="text" nz-input placeholder="Rechercher une structure" formControlName="search" />
          </nz-input-group>
        </div>
      </div>
    </form>
    <nz-table
      nzShowSizeChanger
      [nzData]="datas ? datas.data : []"
      [nzFrontPagination]="false"
      [nzLoading]="isLoading"
      [nzTotal]="datas ? datas.metadata.totalResults : 0"
      [nzPageSize]="limit"
      [nzPageIndex]="datas ? datas.metadata.currentPage : 0"
      [nzNoResult]="emptyTemplate"
      (nzQueryParams)="setPage($event)"
      *ngIf="!error; else errorTemplate">
      <thead>
        <tr>
          <th nzWidth="60px" *ngIf="userCanDelete"></th>
          <th nzColumnKey="active" nzWidth="60px" [nzSortFn]="true">Actif</th>
          <th nzColumnKey="name" [nzSortFn]="true">Nom</th>
          <th nzColumnKey="name2" [nzSortFn]="true">Raison sociale</th>
          <th nzColumnKey="address">Adresse</th>
          <th nzColumnKey="preparers">Préparateurs</th>
          <th nzColumnKey="clientSites">Sites client</th>
          <th nzColumnKey="actions" class="actions">Actions</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let structure of datas?.data">
          <tr>
            <td [nzChecked]="checked.includes(structure.id)" (nzCheckedChange)="setChecked($event, structure.id)" *ngIf="userCanDelete"></td>
            <td><label nz-checkbox nzDisabled [(ngModel)]="structure.active"></label></td>
            <td>{{ structure.name }}</td>
            <td>{{ structure.name2 }}</td>
            <td>{{ structure.fullAddress }}</td>
            <td>
              <button nz-button (click)="goToPreparers(structure)" [disabled]="structure.preparersMetaData.totalResults === 0">
                {{ preparers(structure) }}
                <i nz-icon nzType="right-square" nzTheme="outline" *ngIf="(structure.preparersMetaData?.totalResults ? structure.preparersMetaData.totalResults : 0) > 0"></i>
              </button>
            </td>
            <td>
              <button nz-button (click)="goToCustomerSites(structure)" [disabled]="structure.customerSitesMetaData.totalResults === 0">
                {{ customerSites(structure) }}
                <i nz-icon nzType="right-square" nzTheme="outline" *ngIf="(structure.customerSitesMetaData?.totalResults ? structure.customerSitesMetaData.totalResults : 0) > 0"></i>
              </button>
            </td>
            <td class="actions">
              <button nz-button class="mr-8" [routerLink]="['/', 'admin', 'structures', structure.id]">
                <i nz-icon nzType="eye"></i>
                Détails
              </button>
              <button nz-button class="mr-8" (click)="edit(structure)" *ngIf="userCanEdit">Modifier</button>
              <button nz-button nzType="primary" (click)="delete(structure)" *ngIf="structure.active && userCanDelete">
                <i nz-icon nzType="delete"></i>
              </button>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </nz-table>
    <ng-template #errorTemplate>
      <nz-result
        nzTitle="Erreur de chargement"
        nzStatus="error"
        nzSubTitle="Une erreur est survenue lors du chargement de la liste des structures">
        <div nz-result-extra>
          <button nz-button nzType="primary" (click)="loadData()">
            Réessayer
            <i nz-icon nzType="reload"></i>
          </button>
        </div>
      </nz-result>
    </ng-template>
    <ng-template #emptyTemplate>
      <nz-empty
        nzNotFoundImage="simple"
        nzNotFoundContent="Aucune structure trouvée. Commencez par en ajouter un en utilisant le bouton ci-dessous."
        [nzNotFoundFooter]="emptyFooter">
        <ng-template #emptyFooter>
          <button nz-button nzType="primary" (click)="add()">
            <i nz-icon nzType="plus"></i>
            Ajouter une structure
          </button>
        </ng-template>
      </nz-empty>
    </ng-template>
  </div>
</nz-card>
