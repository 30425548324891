import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzResultModule } from 'ng-zorro-antd/result';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { SharedModule } from 'src/app/shared/shared.module';
import { MainSharedModule } from '../../shared/main-shared.module';
import { AdminParamsComponent as AdminParametersComponent } from './admin-params.component';
import { VehicleCheckpointComponent } from './vehicle-checkpoint/vehicle-checkpoint.component';
import { VehicleCheckpointDetailComponent } from './vehicle-checkpoint/vehicle-checkpoint-detail/vehicle-checkpoint-detail.component';
import { VehicleCheckpointFormComponent } from './vehicle-checkpoint/vehicle-checkpoint-form/vehicle-checkpoint-form.component';
import { VehicleModelComponent } from './vehicle-model/vehicle-model.component';
import { VehicleModelDetailComponent } from './vehicle-model/vehicle-model-detail/vehicle-model-detail.component';
import { VehicleModelFormComponent } from './vehicle-model/vehicle-model-form/vehicle-model-form.component';
import { SelectVehicleCategoryModule } from '../../../../shared/views/inputs/select-vehicle-category/select-vehicle-category.module';
import { SelectVehicleBrandModule } from '../../../../shared/views/inputs/select-vehicle-brand/select-vehicle-brand.module';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { VehicleBrandComponent } from './vehicle-brand/vehicle-brand.component';
import { VehicleModelListComponent } from './vehicle-model-list/vehicle-model-list.component';
import { VehicleBrandListComponent } from './vehicle-brand-list/vehicle-brand-list.component';
import { VehicleCheckpointListComponent } from './vehicle-checkpoint-list/vehicle-checkpoint-list.component';
import { VehicleBrandDetailComponent } from './vehicle-brand/vehicle-brand-detail/vehicle-brand-detail.component';
import { VehicleBrandFormComponent } from './vehicle-brand/vehicle-brand-form/vehicle-brand-form.component';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { RseListComponent } from './rse-list/rse-list.component';
import {NzModalComponent} from "ng-zorro-antd/modal";
import {NzUploadComponent} from "ng-zorro-antd/upload";

@NgModule({
  declarations: [
    AdminParametersComponent,
    VehicleCheckpointComponent,
    VehicleCheckpointDetailComponent,
    VehicleCheckpointFormComponent,
    VehicleModelComponent,
    VehicleModelDetailComponent,
    VehicleModelFormComponent,
    VehicleModelListComponent,
    VehicleBrandListComponent,
    VehicleCheckpointListComponent,
    VehicleBrandDetailComponent,
    VehicleBrandFormComponent,
    VehicleBrandComponent,
    RseListComponent
  ],
    imports: [
        CommonModule,
        SharedModule,
        MainSharedModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        NzButtonModule,
        NzCardModule,
        NzCheckboxModule,
        NzDropDownModule,
        NzFormModule,
        NzTableModule,
        NzIconModule,
        NzInputModule,
        NzInputNumberModule,
        NzResultModule,
        NzEmptyModule,
        NzTabsModule,
        SelectVehicleBrandModule,
        SelectVehicleCategoryModule,
        NzRadioModule,
        NzSelectModule,
        NzModalComponent,
        NzUploadComponent
    ],
  exports: [
    AdminParametersComponent
  ]
})
export class AdminParamsModule {}
