import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {EntityWrapperComponent} from '../../shared/entity-wrapper/entity-wrapper.component';
import {UserService} from 'src/app/shared/services/api/user.service';
import {UserRight} from 'src/app/shared/models/entities/user-right';
import {Title} from '@angular/platform-browser';
import {InvoiceRequest} from "../../../../shared/models/entities/invoice-request";
import {InvoiceRequestsService} from "../../../../shared/services/api/invoice-requests.service";

@Component({
  selector: 'laveo-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(EntityWrapperComponent) entityWrapperComponent: EntityWrapperComponent;

  isReady = false;
  invoice: InvoiceRequest;
  editEnabled = false;
  deleteEnabled = false;

  private subscriptions: Subscription[] = [];

  constructor(
    public readonly invoicesService: InvoiceRequestsService,
    private readonly userService: UserService,
    private readonly titleService: Title,
  ) {}

  get title(): string {
    const invoice = this.invoice;
    if (invoice) {
      if (invoice.customerSite) {
        return invoice.reference + " - " + invoice.customerSite.name ;
      }
      return invoice.reference + " - " + invoice.customer.name ;

    }

    if (invoice === null) {
      return 'Nouvelle facture';
    }

    return '';
  }

  get description(): string {
    const invoice = this.invoice;
    return invoice?.reference ?? '';
  }

  ngOnInit(): void {
    this.loadRole();
  }

  ngAfterViewInit(): void {
    this.subscriptions.push(this.entityWrapperComponent?.entity
      .subscribe(entity => {
        this.invoice = entity as InvoiceRequest;
        if (this.invoice !== undefined) {
          this.isReady = true;
        }
        this.setTitle();
      }));
  }

  ngOnDestroy(): void {
    for (const s of this.subscriptions) {
      s.unsubscribe();
    }
  }

  private loadRole(): void {
    const roleSubscription = this.userService.currentRole.subscribe(role => {
      this.editEnabled = role.rights.invoiceRequests.includes(UserRight.update);
      this.deleteEnabled = role.rights.invoiceRequests.includes(UserRight.delete);
    });

    if (roleSubscription) {
      this.subscriptions.push(roleSubscription);
    }
  }

  private setTitle(): void {
    this.titleService.setTitle(`Lavéo - ${this.title}`);
  }
}
