import { Pipe, PipeTransform } from '@angular/core';
import {ProviderInvoiceOrderActions} from "../models/entities/provider-invoice-order-actions";

@Pipe({
  name: 'providerInvoiceOrderActionsIcon'
})
export class ProviderInvoiceOrderActionsIconPipe implements PipeTransform {
  transform(action: ProviderInvoiceOrderActions): string | null {
    switch (action) {
      case ProviderInvoiceOrderActions.validate:{
        return 'check';
      }
      default: {
        return null;
      }
    }
  }
}
