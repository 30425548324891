<laveo-entity-wrapper
  [title]="title"
  [description]="description"
  [entityService]="providerInvoiceOrdersService"
  [getFunction]="providerInvoiceOrdersService.providerInvoiceOrder"
  [wrappedComponent]="this"
  [editEnabled]="false"
  [deleteEnabled]="false"
  [actionButtonEnabled]="false">
  <laveo-provider-invoice-order-detail *ngIf="isReady" detail [providerOrder]="providerInvoiceOrder"></laveo-provider-invoice-order-detail>
</laveo-entity-wrapper>
