<form [formGroup]="form">
  <div nz-row nzGutter="16" class="searchZone">
    <div nz-col nzSpan="6">
      <nz-range-picker formControlName="dateRange"></nz-range-picker>
    </div>
    <div nz-col nzSpan="8">
      <nz-select  class="w100" formControlName="customer" nzShowSearch nzPlaceHolder="Choisissez un client" (ngModelChange)="onCustomerChange($event)">
        <nz-option *ngFor="let customer of customers" [nzValue]="customer.id" [nzLabel]="customer.name"></nz-option>
      </nz-select>
    </div>
    <div nz-col nzSpan="8">
      <nz-select  class="w100" formControlName="customerSite" nzShowSearch nzPlaceHolder="Choisissez un site client" [nzDisabled]="!selectedCustomer">
        <nz-option *ngFor="let site of customerSites" [nzValue]="site.id" [nzLabel]="site.name"></nz-option>
      </nz-select>
    </div>

    <div nz-col nzSpan="2">
      <button nz-button nzType="primary" (click)="onSearch()">Search</button>
    </div>
  </div>
</form>

<div nz-row nzGutter="16">
  <div nz-col nzSpan="12">
    <div style="display: block;">
      <canvas baseChart
              [data]="chartDataMonthly"
              [options]="chartOptionsMonthly"
              chartType="bar">
      </canvas>
    </div>
    <div style="display: block;">
      <canvas baseChart
              [data]="chartDataDaily"
              [options]="chartOptionsDaily"
              chartType="bar">
      </canvas>
    </div>
  </div>
  <div nz-col nzSpan="12" class="summary">
    <div class="summary-item">
      <h2>Chiffre d'affaire facturé total sur la période</h2>
      <p>{{ totalAmountWithoutVatSumMonthly | number }} &euro; HT</p>
    </div>
    <div class="summary-item">
      <h2>Chiffre d'affaire facturé HT du mois en cours</h2>
      <p>{{ currentMonthTotalAmountWithoutVat | number }} &euro; HT</p>
    </div>
    <div class="summary-item">
      <h2>Chiffre d'affaire facturé HT du jour</h2>
      <p>{{ currentDailyTotalAmountWithoutVat| number }} &euro; HT</p>
    </div>
  </div>
</div>
