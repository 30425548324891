import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {FormControl, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {debounceTime, take} from 'rxjs/operators';
import {BehaviorSubject, Subscription} from 'rxjs';
import {
  addMonths,
  endOfMonth,
  endOfToday,
  endOfWeek,
  isEqual,
  startOfMonth,
  startOfToday,
  startOfWeek,
  startOfYear
} from 'date-fns';
import {NzMessageService} from 'ng-zorro-antd/message';
import {NzModalRef, NzModalService} from 'ng-zorro-antd/modal';
import {UserService} from 'src/app/shared/services/api/user.service';
import {ApolloError} from '@apollo/client/core';
import {PagedRequest} from "../../../../shared/models/api/shared/paged/paged-request";
import {ActorsService} from "../../../../shared/services/api/actors.service";
import {ApiErrorMessageUtil} from "../../../../shared/utils/api-error-message.util";
import {
  ProviderInvoiceOrder,
  ProviderInvoiceOrderStatus
} from "../../../../shared/models/entities/provider-invoice-order";
import {
  ProviderInvoiceOrdersFiltersRequest
} from "../../../../shared/models/api/services/provider-invoice-orders-filters-requests.interface";
import {Structure} from "../../../../shared/models/entities/structure";
import {ProviderInvoiceOrdersService} from "../../../../shared/services/api/provider-invoice-orders.service";
import {ProviderInvoiceOrderActions} from "../../../../shared/models/entities/provider-invoice-order-actions";

@Component({
  selector: 'laveo-filter-provider-invoice-orders',
  templateUrl: './filter-provider-invoice-orders.component.html',
  styleUrls: ['./filter-provider-invoice-orders.component.scss']
})
export class FilterProviderInvoiceOrdersComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() originalList: ProviderInvoiceOrder[] = [];
  @Input() checked: ProviderInvoiceOrder[] = [];
  @Input() queryStringFilters = new BehaviorSubject<PagedRequest<ProviderInvoiceOrdersFiltersRequest> | null>(null);
  @Input() autofocus = false;
  @Output() filtersChanged = new EventEmitter<PagedRequest<ProviderInvoiceOrdersFiltersRequest>>();
  @Output() checkedChanged = new EventEmitter();

  @ViewChild('search') private searchInput: ElementRef<HTMLInputElement>;

  actionLoading = false;
  filtersAreVisible = false;
  filters: UntypedFormGroup;
  filterStatus: ProviderInvoiceOrderStatus[] = Object.values(ProviderInvoiceOrderStatus);
  filterClientTypeEntity = [Structure];
  userCanSeeInvoiceFilter = false;
  userCanSeeCustomerFilter = true;
  filterStructurePlaceholder = 'Structures';
  dateRanges: Record<string, Date[]> = {};
  defaultTime = new Date(0, 0, 0, 0, 0, 0);
  cancelComment = new FormControl('', [Validators.required]);

  private subscriptions: Subscription[] = [];

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly providerInvoiceOrderService: ProviderInvoiceOrdersService,
    private readonly actorsService: ActorsService,
    private readonly userService: UserService,
    private readonly modal: NzModalService,
    private readonly message: NzMessageService
  ) {}

  get checkedContainsDeletable(): boolean {
    const cancelable = this.checked.find(order => order.status === ProviderInvoiceOrderStatus.created);
    return cancelable !== undefined;
  }

  get checkedPossibleActions(): ProviderInvoiceOrderActions[] {

    return [
      ProviderInvoiceOrderActions.validate]

  }

  get cancellable(): ProviderInvoiceOrder[] {
    return this.checked.filter(order => [
      ProviderInvoiceOrderStatus.created
    ].includes(order.status));
  }

  ngOnInit(): void {
    this.setFiltersForm();
    this.loadRole();

    this.subscriptions.push(
      this.queryStringFilters.subscribe(f => this.setFilterFromQueryString(f))
    );
  }

  ngAfterViewInit(): void {
    if (this.autofocus) {
      setTimeout(() => {
        this.searchInput.nativeElement.focus();
      }, 100);
    }
  }

  ngOnDestroy(): void {
    for (const s of this.subscriptions) {
      s.unsubscribe();
    }
  }

  toggleFilter(): void {
    this.filtersAreVisible = !this.filtersAreVisible;
  }
 validateChecked(content: TemplateRef<any>, footer: TemplateRef<any>): void {
    this.modal.create({
      nzTitle: 'Validation multiple',
      nzContent: content,
      nzFooter: footer
    });
  }

  cancelChecked(content: TemplateRef<any>, footer: TemplateRef<any>): void {
    this.modal.create({
      nzTitle: 'Annulation multiple',
      nzContent: content,
      nzFooter: footer
    });
  }


  doActionForChecked(action: ProviderInvoiceOrderActions): void {
    switch (action) {
      case ProviderInvoiceOrderActions.validate: {
        this.validate();
        break;
      }
    }
  }

  loadRole(): void {
    const roleSubscription = this.userService.currentRole.subscribe((role) => {
     console.log("roles loaded", role);

    });
    if (roleSubscription) {
      this.subscriptions.push(roleSubscription);
    }
  }

  private setFiltersForm(): void {
    this.filters = this.formBuilder.group({
      orderd: this.formBuilder.control(null),
      search: this.formBuilder.control(null),
      status: this.formBuilder.control([]),
      structures: this.formBuilder.control([]),
      dates: this.formBuilder.control([startOfYear(startOfToday()), startOfToday()])
    });


    this.setFilterFromQueryString();

    this.dateRanges = {
      'Aujourd\'hui': [startOfToday(), endOfToday()],
      'Cette semaine': [startOfWeek(startOfToday(), { weekStartsOn: 1 }), endOfWeek(startOfToday(), { weekStartsOn: 1 })],
      'Ce mois': [startOfMonth(startOfToday()), endOfMonth(startOfToday())]
    };

    const filterSubscription = this.filters.valueChanges.pipe(debounceTime(500)).subscribe((filtersForm: FiltersFormValues) => {
      let minDate = filtersForm.dates.length > 0 ? filtersForm.dates[0] : undefined;
      let maxDate = filtersForm.dates.length > 0 ? filtersForm.dates[1] : undefined;
      if (minDate && isEqual(minDate, startOfToday()) && maxDate && isEqual(maxDate, addMonths(startOfToday(), 6))) {
        minDate = undefined;
        maxDate = undefined;
      }
      minDate?.setHours(0, 0, 0, 0);
      maxDate?.setHours(23, 59, 29, 999);

      this.filtersChanged.emit(new PagedRequest<ProviderInvoiceOrdersFiltersRequest>({
        page: this.queryStringFilters.value?.page,
        filters: {
          minDate,
          maxDate,
          status: filtersForm.status,
          structures: filtersForm.structures.filter(c => c.__typename === 'Structure').map(c => c.id),
        },
        search: filtersForm.search
      }));

      if (this.checked.length > 0) {
        this.checked.splice(0, this.checked.length);
        this.checkedChanged.emit();
      }
    });

    this.subscriptions.push(filterSubscription);
  }

  private setFilterFromQueryString(queryStringFilters: PagedRequest<ProviderInvoiceOrdersFiltersRequest> | null = null): void {
    if (!queryStringFilters) {
      queryStringFilters = this.queryStringFilters.value;
    }

    if (!queryStringFilters) {
      return;
    }

    let dates: Date[] = this.filters.get('dates')?.value ?? [];
    if (queryStringFilters?.filters?.minDate && queryStringFilters?.filters?.maxDate) {
      dates = [queryStringFilters?.filters?.minDate, queryStringFilters?.filters?.maxDate];
    }

    const ids = [...(queryStringFilters?.filters?.structures ?? []), ...(queryStringFilters?.filters?.structures ?? [])];
    const structures: (Structure)[] = [];

    const afterRequest = () => {
      this.filters.patchValue({
        search: queryStringFilters?.search,
        status: queryStringFilters?.filters?.status ?? [],
        structures,
        dates
      });

      let hasFilters = [
        this.filters.get('status')?.value,
        this.filters.get('customers')?.value,
      ].some(filter => filter?.length > 0);

      const filterDates = this.filters.get('dates')?.value as Date[];
      const hasFilterDates = filterDates.length > 0 && (!isEqual(filterDates[0], startOfToday()) || !isEqual(filterDates[1], addMonths(startOfToday(), 6)));
      hasFilters = hasFilters || hasFilterDates;

      this.filtersAreVisible = this.filtersAreVisible || hasFilters;
    };

    if (ids.length > 0) {
      this.actorsService.actorsByIds(ids).pipe(take(1)).subscribe({
        next: response => {
          response.data.forEach(actor => {
            if (actor instanceof Structure) {
              structures.push(actor);
            }

          });
          afterRequest();
        },
        error: (error: ApolloError) => {
          afterRequest();
          this.message.error(ApiErrorMessageUtil.getMessageFromError(error));
        }});
    } else {
      afterRequest();
    }
  }

  // Checked actions
  private validate(): void {
    const confirmable = this.checked.filter(order => order.status === ProviderInvoiceOrderStatus.created);
    let nzContent = `Êtes-vous sûr de vouloir valider <b>${confirmable.length > 1 ? `les ${confirmable.length} bons de commande sélectionnés` : 'le bon de commande sélectionné'}</b> ?`;
    if (confirmable.length !== this.checked.length) {
      const notConfirmableLength = this.checked.length - confirmable.length;
      nzContent += notConfirmableLength === 1 ? '<br><br> (' + notConfirmableLength + ' autre bon de commande ne peut pas être validée à cause de son statut)' : '<br><br> (' + notConfirmableLength + ' autres bons de commande ne peuvent pas être validés à cause de leurs statuts)';
    }

    nzContent += `<br />Attention : une fois le bon de commande validé, il ne pourra plus être modifié ni annulé.<br /><br/>
        Êtes-vous vraiment sûr(e) de vouloir continuer ? `;

    this.modal.confirm({
      nzTitle: 'Validation multiple',
      nzContent,
      nzOkText: 'Valider',
      nzOkType: 'primary',
      nzCancelText: 'Annuler',
      nzOnOk: () => {
        this.actionLoading = true;
        const messageReference = this.message.loading('Validation en cours...');
        this.providerInvoiceOrderService.validateInvoiceMultiple(...confirmable.map(s => s.id)).subscribe({
          next: response => {
            this.message.remove(messageReference.messageId);
            if ((response.data?.length ?? 0) === 0) {
              this.message.error('Une erreur est survenue lors de la validation. Réessayer plus tard.');
            } else if ((response.data?.length ?? 0) === 1) {
              this.message.info(`1 bon de commande validé avec succès.`);
            } else {
              this.message.info(`${response.data?.length} bons de commandes validés avec succès.`);
            }

            // Override existing values
            if (response.data) {
              this.updateProviderInvoiceOrderList(this.originalList, response.data);
            }
            this.actionLoading = false;
            this.checked.splice(0, this.checked.length);
          },
          error: (error: ApolloError) => {
            this.message.remove(messageReference.messageId);
            console.error(error);
            this.actionLoading = false;
            this.message.error(ApiErrorMessageUtil.getMessageFromError(error));
          }
        });
      }
    });
  }

  closeCancelModal(modal: NzModalRef): void {

    this.actionLoading = true;
    this.providerInvoiceOrderService.cancelMultiple(...this.cancellable.map(s => s.id)).subscribe({
      next: response => {
        if ((response.data?.length ?? 0) === 0) {
          this.message.error('Une erreur est survenue lors de l\'annulation. Réessayer plus tard.');
        } else if ((response.data?.length ?? 0) === 1) {
          this.message.info(`1 bon de commande annulé avec succès.`);
        } else {
          this.message.info(`${response.data?.length} bons de commandes annulés avec succès.`);
        }
        this.actionLoading = false;
        this.checked.splice(0, this.checked.length);
        if (this.queryStringFilters?.value) {
          this.queryStringFilters.value.page = 1;
        }
        this.checkedChanged.emit();
        modal.destroy();
      },
      error: (error: ApolloError) => {
        console.error(error);
        this.actionLoading = false;
        this.message.error(ApiErrorMessageUtil.getMessageFromError(error));
      }
    });
  }


  private updateProviderInvoiceOrderList(originalList: ProviderInvoiceOrder[], updatedList: ProviderInvoiceOrder[]): ProviderInvoiceOrder[] {
    updatedList.forEach(updatedItem => {
      const index = originalList.findIndex(item => item.id === updatedItem.id);
      if (index >= 0) {
        originalList[index] = updatedItem;
      }
    });
    return originalList;
  }




}

interface FiltersFormValues {
  search: string;
  status: ProviderInvoiceOrderStatus[];
  structures: (Structure)[];
  dates: Date[];
}
