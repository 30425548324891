import { Pipe, PipeTransform } from '@angular/core';
import {StatusInvoice} from "../models/entities/invoice-request";

@Pipe({
  name: 'invoiceStatusName'
})
export class InvoiceStatusNamePipe implements PipeTransform {



  transform(status?: StatusInvoice, short = false): string | undefined {
    if (!status) {
      return;
    }

    switch (status) {
      case StatusInvoice.invoiced: {
        return 'Facturée';
      }
      case StatusInvoice.validationRequired: {
        return short ? 'Validation requise' : 'En attente de validation';
      }
      case StatusInvoice.customerValidationRequired: {
        return short ? 'Attente Numéro Commande' : 'Attente du numéro de commande du client';
      }
      case StatusInvoice.validated: {
        return 'Validée';
      }
      case StatusInvoice.created: {
        return 'Créée';
      }
      case StatusInvoice.paid: {
        return 'Payée';
      }

      default: {
        return;
      }
    }
  }
}
