import { Pipe, PipeTransform } from '@angular/core';
import { UserRightCategory } from '../models/entities/user-right';

@Pipe({
  name: 'rightIcon'
})
export class RightIconPipe implements PipeTransform {
  transform(right: UserRightCategory): string {
    switch (right) {
      case UserRightCategory.services: {
        return 'share-alt';
      }
      case UserRightCategory.users:
      case UserRightCategory.customers: {
        return 'user';
      }
      case UserRightCategory.vehicles: {
        return 'car';
      }
      case UserRightCategory.customerSites: {
        return 'pushpin';
      }
      case UserRightCategory.preparers: {
        return 'clear';
      }
      case UserRightCategory.structures: {
        return 'shop';
      }
      case UserRightCategory.invoiceRequests: {
        return 'euro';
      }
      default: {
        return 'setting';
      }
    }
  }
}
