import {Deserializer, Type} from 'typeserializer';
import {Entity} from './entity';
import {DateConverter} from "../../type-converters/date.converter";
import {ProviderInvoiceOrderDetail} from "./provider-invoice-order-detail";
import {Structure} from "./structure";
import {CustomerSite} from "./customer-site";

export class ProviderInvoiceOrder extends Entity {

  @Type(Structure)
  structure: Structure;

  @Type(CustomerSite)
  customerSite: CustomerSite;
  reference: string;
  status: ProviderInvoiceOrderStatus;
  comment: string;
  totalAmountWithoutVat: number;
  providerInvoiceOrderDetail: ProviderInvoiceOrderDetail[];
  isOperationPointed: boolean;
  pennylaneFileUrl: string;
  autoInvoiceEnabled: boolean;

  @Type(Date)
  @Deserializer(DateConverter.deserialize)
  invoiceDate: Date | undefined;

  @Type(Date)
  @Deserializer(DateConverter.deserialize)
  deadlineDate: Date | undefined;

  vatRate: string;



  @Type(Date)
  @Deserializer(DateConverter.deserialize)
  updatedDate: Date

}
export class ProviderInvoiceOrderArray {
  @Type([ProviderInvoiceOrder])
  children: ProviderInvoiceOrder[];
}

export enum ProviderInvoiceOrderStatus {
  created = 'CREATED',
  validated = 'VALIDATED',
  invoiced = 'INVOICED',
  paid = 'PAID'

}

