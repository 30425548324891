import { Pipe, PipeTransform } from '@angular/core';
import {ProviderInvoiceOrderActions} from "../models/entities/provider-invoice-order-actions";

@Pipe({
  name: 'providerInvoiceOrderActionsName'
})
export class ProviderInvoiceOrderActionsNamePipe implements PipeTransform {
  transform(action: ProviderInvoiceOrderActions): string | null {
    switch (action) {
      case ProviderInvoiceOrderActions.validate: {
        return 'Valider';
      }
      default: {
        return null;
      }
    }
  }
}
