import { Type, Deserializer } from 'typeserializer';
import { Actor } from './actor';
import { CustomerSite } from './customer-site';
import { Preparer } from './preparer';
import { PagedResponseConverter } from '../../type-converters/paged-response.converter';
import { PagedResponseMetadata } from '../api/shared/paged/paged-response.interface';
import addressFormatter from '@fragaria/address-formatter/dist/es/address-formatter';
import { Contact } from './contact';
import { LaveoFile } from './file';

export class Structure extends Actor {
  @Type(LaveoFile)
  logo?: LaveoFile;
  @Deserializer(PagedResponseConverter.deserialize)
  preparers: Preparer[];
  @Deserializer(PagedResponseConverter.deserialize)
  customerSites: CustomerSite[];
  @Type([Contact])
  contacts: Contact[];

  /** Le nom original de la structure */
  name2: string;
  address: string;
  city: string;
  postalCode: string;
  country: string;
  latitude: number;
  longitude: number;


  // Invoicing

  hasVat: boolean;
  isAutoInvoiceEnabled: boolean;
  registrationId: string;
  vatNumber: string;
  legalStatus: string;
  shareCapital: number;
  rcsNumber: string;
  rcsCity: string
  nafCode: string;
  otherInvoiceInfo: string;


  preparersMetaData: PagedResponseMetadata;
  customerSitesMetaData: PagedResponseMetadata;

  public get fullAddress(): string {
    const address: string = addressFormatter.format(
      {
        road: this.address,
        city: this.city,
        postcode: this.postalCode,
        countryCode: this.country,
      },
      {
        appendCountry: true,
        fallbackCountryCode: 'FR',
      } as any
    ) as string;
    return address.trim().replaceAll('\n', ', ');
  }
}

export class StructureArray {
  @Type([Structure])
  children: Structure[];
}

export enum StructureVat {
  fr20 = 'TWENTY',
  exempt = 'EXEMPT',
}

