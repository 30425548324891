import { Pipe, PipeTransform } from '@angular/core';
import {BillingFrequency} from "../models/entities/customer-site";

@Pipe({
  name: 'customerSiteFrequency'
})
export class CustomerSiteFrequencyPipe implements PipeTransform {
  transform(type?: BillingFrequency): string | undefined {
    if (!type) {
      return;
    }

    switch (type) {
      case BillingFrequency.monthly: {
        return 'Mensuelle';
      }
      case BillingFrequency.weekly: {
        return 'Hebdomadaire';
      }
      default: {
        return 'Manuelle';
      }
    }
  }
}
