import { Actor } from './actor';
import { Customer } from './customer';
import { CustomerSiteBillingPerson } from './customer-site-billing-person';
import { Structure } from './structure';
import { CustomerSiteContractType } from './customer-site-contract-type';
import { CustomerSiteWorkflowType } from './customer-site-workflow-type';
import { Type} from 'typeserializer';
import addressFormatter from '@fragaria/address-formatter/dist/es/address-formatter';
import { Contact } from './contact';
import { LaveoFile } from './file';
import { ServiceUpholsteryStainRemoval, ServiceCarpetStainRemoval, ServiceType, ServiceFinishing } from './service';
import { VehicleCheckpoint } from './vehicle-checkpoint';

export class CustomerSite extends Actor {
  @Type(LaveoFile)
  logo?: LaveoFile;

  @Type([Contact])
  contacts: Contact[];

  @Type(Structure)
  structure: Structure;

  address: string;
  city: string;
  postalCode: string;
  country: string;
  latitude: number;
  longitude: number;
  customer: Customer;
  billingPerson: CustomerSiteBillingPerson;
  billingText: string;
  shelteredSite: boolean;
  electricityAccess: boolean;
  contractType: CustomerSiteContractType;
  frequencyServiceAffectedVehicle: number;
  frequencyServiceMutualizedVehicle: number;
  workflowType: CustomerSiteWorkflowType;
  type: ServiceType[];
  finishing: ServiceFinishing[];
  firstIntervention: boolean;
  vehicleVeryDirty: boolean;
  upholsteryStainRemoval: ServiceUpholsteryStainRemoval[];
  carpetStainRemoval: ServiceCarpetStainRemoval[];
  conveying: boolean;
  sticking: boolean;
  desticking: boolean;
  contactZoneDisinfection: boolean;
  interiorDisinfection: boolean;
  vehicleCheckpoints: VehicleCheckpoint[];

  // Billing
  phoneNumber: string;
  billingEnabled: boolean;
  billingAddress1: string;
  billingAddress2: string;
  billingPostalCode: string;
  billingCity: string;
  billingCountry: string;
  billingEmail: string;
  billingAgreementEmail: string;
  billingByVehicle: boolean;
  billingIsOrderNumberRequired: boolean;
  billingToUploadOnTool: boolean;
  billingIsAgreementRequiredBeforeInvoicing: boolean;
  billingRecipient: string;
  billingUploadToolUrl: string;
  billingFrequency: BillingFrequency;
  billingPennylaneDraftMode: boolean;
  billingAdditionalParamsForProductLabel: string;
  billingGroupByCustomer: boolean;
  billingNeedsManualValidation: boolean;
  billingYearlyOrderNumber: string;
  invoiceItemPrefix: string;
  registrationId: string;
  vatNumber: string;



  public get fullAddress(): string {
    const address: string = addressFormatter.format(
      {
        road: this.address,
        city: this.city,
        postcode: this.postalCode,
        countryCode: this.country,
      },
      {
        appendCountry: true,
        fallbackCountryCode: 'FR',
      } as any
    ) as string;
    return address.trim().replaceAll('\n', ', ');
  }
}

export class CustomerSiteArray {
  @Type([CustomerSite])
  children: CustomerSite[];
}

export enum BillingFrequency {
  monthly = 'MONTHLY',
  weekly = 'WEEKLY',
  manual = 'MANUAL'
}
