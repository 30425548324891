import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ChartData, ChartOptions } from 'chart.js';
import { InvoiceRequestsService } from "../../../../shared/services/api/invoice-requests.service";
import {BillingStatisticsMonthly} from "../../../../shared/models/entities/billing-statistics-monthly";
import {BillingStatisticsDaily} from "../../../../shared/models/entities/billing-statistics-daily";
import {CustomersService} from "../../../../shared/services/api/customers.service";
import {PagedRequest} from "../../../../shared/models/api/shared/paged/paged-request";
import {CustomerSitesService} from "../../../../shared/services/api/customer-sites.service";
import {CustomerSite} from "../../../../shared/models/entities/customer-site";
import {Customer} from "../../../../shared/models/entities/customer";

@Component({
  selector: 'laveo-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss']
})
export class StatisticsComponent implements OnInit {
  form: FormGroup;
  customers: Customer[] = [];
  customerSites: CustomerSite[] | undefined = [];
  selectedCustomer: Customer | undefined;
  chartDataMonthly: ChartData<'bar' | 'line'>;
  chartOptionsMonthly: ChartOptions<'bar' | 'line'> = {
    responsive: true,
    scales: {
      x: {},
      y: {
        beginAtZero: true
      }
    }
  };
  chartDataDaily: ChartData<'bar' | 'line'>;
  chartOptionsDaily: ChartOptions<'bar' | 'line'> = {
    responsive: true,
    scales: {
      x: {},
      y: {
        beginAtZero: true
      }
    }
  };
  totalAmountWithoutVatSumMonthly = 0;
  currentMonthTotalAmountWithoutVat = 0;
  totalAmountWithoutVatSumDaily = 0;
  currentDailyTotalAmountWithoutVat = 0;

  constructor(private fb: FormBuilder,
              private customerService: CustomersService,
              private customerSiteService: CustomerSitesService,
              private invoiceRequestService: InvoiceRequestsService) {
    const currentYear = new Date().getFullYear();
    const from = new Date(currentYear, 0, 1); // First day of the year
    const to = new Date(currentYear, 11, 31); // Last day of the year

    this.form = this.fb.group({
      dateRange: [[from, to]],
      customer: [null],
      customerSite: [null]
    });
    this.loadCustomers();
  }


  loadCustomers(): void {
    const pr = new PagedRequest({ limit: 50, page: 1, search: '' });

    this.customerService.allCustomers(pr).subscribe(customers => {
      this.customers = customers.data?.data;
    });
  }
  onCustomerChange(customerId: string): void {
    this.selectedCustomer = this.customers.find(c => c.id === customerId);
    this.customerSites = [];
    this.form.get('customerSite')?.setValue(null); // Reset customerSite selection
    this.loadCustomerSites(customerId, "");
  }

  loadCustomerSites(customerId: string, search: string): void {
    this.customerSiteService.fromCustomer(customerId, search).subscribe(sites => {
      this.customerSites = sites;
    });
  }


  ngOnInit(): void {

    const currentYear = new Date().getFullYear();
    const from = new Date(currentYear, 0, 1); // First day of the year
    const to = new Date(currentYear, 11, 31); // Last day of the year
    this.loadStatistics(from, to);
  }

  loadStatistics(from: Date, to: Date, customer: string | undefined = undefined, customerSite: string | undefined = undefined): void {


    const customerId = customer ?? null;
    const customerSiteId = customerSite ?? null;
    console.log("Load statistics for customer", customerId, "and customer site", customerSiteId);

    this.invoiceRequestService.billingStatistics(from, to, customerId, customerSiteId).subscribe(result => {
      const data = result.data?.invoiceRequests?.getBillingStatistics;
      if (data !== null) {
        this.processMonthlyStatisticsData(data.monthly);
        this.processDailyStatisticsData(data.daily)
      }
    });
  }

  private processMonthlyStatisticsData(data: BillingStatisticsMonthly[]) {

    const labels = data.map(item => `${item.year}-${item.month}`);
    const totalAmountWithoutVat = data.map(item => item.totalAmountWithoutVat);
    const providerTotalAmountWithoutVat = data.map(item => item.providerTotalAmountWithoutVat);
    const margin = data.map(item => item.margin);

    this.totalAmountWithoutVatSumMonthly = totalAmountWithoutVat.reduce((sum, value) => sum + value, 0);

    const currentMonth = new Date().getMonth() + 1;
    const currentYear = new Date().getFullYear();
    this.currentMonthTotalAmountWithoutVat = data
      .filter(item => item.month === currentMonth && item.year === currentYear)
      .reduce((sum, item) => sum + item.totalAmountWithoutVat, 0);

    this.chartDataMonthly = {
      labels,
      datasets: [
        { label: 'Achat total HT', data: providerTotalAmountWithoutVat, backgroundColor: 'rgba(153, 102, 255, 0.2)', borderColor: 'rgba(153, 102, 255, 1)', borderWidth: 1, type: 'bar' },
        { label: 'Vente total HT', data: totalAmountWithoutVat, backgroundColor: 'rgba(75, 192, 192, 0.2)', borderColor: 'rgba(75, 192, 192, 1)', borderWidth: 1, type: 'bar' },
        { label: 'Marge', data: margin, backgroundColor: 'rgba(255, 159, 64, 0.2)', borderColor: 'rgba(255, 159, 64, 1)', borderWidth: 1, type: 'bar' },
        { label: "Chiffre d'affaire HT", data: totalAmountWithoutVat, borderColor: 'rgba(54, 162, 235, 1)', borderWidth: 2, fill: false, type: 'line' }
      ]
    };
  }

  private processDailyStatisticsData(data: BillingStatisticsDaily[]) {

    const labels = data.map(item => `${item.year}-${item.month}-${item.day}`);
    const totalAmountWithoutVat = data.map(item => item.totalAmountWithoutVat);
    const providerTotalAmountWithoutVat = data.map(item => item.providerTotalAmountWithoutVat);
    const margin = data.map(item => item.margin);

    this.totalAmountWithoutVatSumDaily = totalAmountWithoutVat.reduce((sum, value) => sum + value, 0);

    const currentMonth = new Date().getMonth() + 1;
    const currentYear = new Date().getFullYear();
    const currentDay = new Date().getDay();
    this.currentDailyTotalAmountWithoutVat = data
      .filter(item => item.month === currentMonth && item.year === currentYear && item.day == currentDay)
      .reduce((sum, item) => sum + item.totalAmountWithoutVat, 0);

    this.chartDataDaily = {
      labels,
      datasets: [
        { label: 'Achat total HT', data: providerTotalAmountWithoutVat, backgroundColor: 'rgba(153, 102, 255, 0.2)', borderColor: 'rgba(153, 102, 255, 1)', borderWidth: 1, type: 'bar' },
        { label: 'Vente total HT', data: totalAmountWithoutVat, backgroundColor: 'rgba(75, 192, 192, 0.2)', borderColor: 'rgba(75, 192, 192, 1)', borderWidth: 1, type: 'bar' },
        { label: 'Marge', data: margin, backgroundColor: 'rgba(255, 159, 64, 0.2)', borderColor: 'rgba(255, 159, 64, 1)', borderWidth: 1, type: 'bar' },
        { label: "Chiffre d'affaire HT", data: totalAmountWithoutVat, borderColor: 'rgba(54, 162, 235, 1)', borderWidth: 2, fill: false, type: 'line' }
      ]
    };
  }



  onSearch(): void {

    const { dateRange, customer, customerSite } = this.form.value;
    const [from, to] = dateRange;
    this.loadStatistics(from, to, customer, customerSite);
  }

}
