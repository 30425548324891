import { AfterViewInit, Component, ViewChild, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { StructureFormComponent } from './structure-form/structure-form.component';
import { Subscription } from 'rxjs';
import { Structure } from 'src/app/shared/models/entities/structure';
import { StructuresService } from 'src/app/shared/services/api/structures.service';
import { EntityWrapperComponent } from '../../../shared/entity-wrapper/entity-wrapper.component';
import { UserService } from 'src/app/shared/services/api/user.service';
import { UserRight } from 'src/app/shared/models/entities/user-right';
import { Title } from '@angular/platform-browser';
import {UserRoleType} from "../../../../../shared/models/entities/user-role";

@Component({
  selector: 'laveo-structure',
  templateUrl: './structure.component.html',
  styleUrls: ['./structure.component.scss']
})
export class StructureComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(EntityWrapperComponent) entityWrapperComponent: EntityWrapperComponent;
  @ViewChild(StructureFormComponent) structureFormComponent: StructureFormComponent;

  isReady = false;
  editEnabled = false;
  deleteEnabled = false;
  isAdmin = false;

  structure: Structure;

  private subscriptions: Subscription[] = [];

  constructor(
    public readonly structuresService: StructuresService,
    private readonly userService: UserService,
    private readonly titleService: Title,
  ) {}

  get title(): string {
    return this.structure?.name ?? 'Nouvelle structure';
  }

  get description(): string {
    return this.structure?.name2 ?? '';
  }

  ngOnInit(): void {
    this.loadRole();
  }

  ngAfterViewInit(): void {
    const structureSubscription = this.entityWrapperComponent?.entity.subscribe((structure: Structure) => {
      if (structure !== undefined) {
        this.isReady = true;
      }
      this.structure = structure;
      this.setTitle();
    });

    if (structureSubscription) {
      this.subscriptions.push(structureSubscription);
    }
  }

  ngOnDestroy(): void {
    for (const s of this.subscriptions) {
      s.unsubscribe();
    }
  }

  public form(): UntypedFormGroup {
    return this.structureFormComponent?.form;
  }

  private loadRole(): void {
    const roleSubscription = this.userService.currentRole.subscribe(role => {
      this.isAdmin = role.type === UserRoleType.admin;
      this.editEnabled = role.rights.structures.includes(UserRight.update) || this.isAdmin;
      this.deleteEnabled = role.rights.structures.includes(UserRight.delete) || this.isAdmin;
    });

    if (roleSubscription) {
      this.subscriptions.push(roleSubscription);
    }
  }

  private setTitle(): void {
    this.titleService.setTitle(`Lavéo - ${this.title}`);
  }
}
