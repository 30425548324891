<laveo-entity-wrapper
  [title]="title"
  [description]="description"
  [entityService]="invoicesService"
  [getFunction]="invoicesService.invoiceRequest"
  [wrappedComponent]="this"
  [editEnabled]="false"
  [deleteEnabled]="false"
  [actionButtonEnabled]="false">
  <laveo-invoice-detail *ngIf="isReady" detail [invoice]="invoice"></laveo-invoice-detail>
</laveo-entity-wrapper>
