import { Pipe, PipeTransform } from '@angular/core';
import {InvoiceRequestActions} from "../models/entities/invoice-actions";

@Pipe({
  name: 'invoiceActionsIcon'
})
export class InvoiceActionsIconPipe implements PipeTransform {
  transform(action: InvoiceRequestActions): string | null {
    switch (action) {
      case InvoiceRequestActions.validate:{
        return 'check';
      }
      default: {
        return null;
      }
    }
  }
}
