import {Component, Input} from '@angular/core';

@Component({
  selector: 'laveo-divider',
  templateUrl: './laveo-divider.component.html',
  styleUrl: './laveo-divider.component.scss'
})
export class LaveoDividerComponent {

  @Input() title: string;
  @Input() icon: string;
}
