import { Pipe, PipeTransform } from '@angular/core';
import {ProviderInvoiceOrderStatus} from "../models/entities/provider-invoice-order";

@Pipe({
  name: 'providerInvoiceOrderStatusColor'
})
export class ProviderInvoiceOrderStatusColorPipe implements PipeTransform {
  transform(status?: ProviderInvoiceOrderStatus): string {
    if (!status) {
      return 'default';
    }

    switch (status) {
      case ProviderInvoiceOrderStatus.validated:{
        return 'blue';
      }
      case ProviderInvoiceOrderStatus.invoiced:{
        return 'success';
      }
      case ProviderInvoiceOrderStatus.paid:{
        return '#57bd6c';
      }
      default: {
        return 'default';
      }
    }
  }
}
