import { Pipe, PipeTransform } from '@angular/core';
import { UserRightCategory } from '../models/entities/user-right';
import { UserRoleType } from '../models/entities/user-role';

@Pipe({
  name: 'rightName'
})
export class RightNamePipe implements PipeTransform {
  transform(right: UserRightCategory, userRoleType: UserRoleType = UserRoleType.admin): string {
    const isAdmin = userRoleType === UserRoleType.admin;
    switch (right) {
      case UserRightCategory.services: {
        return isAdmin ? 'Gestion des prestations' : 'Prestations';
      }
      case UserRightCategory.users: {
        return isAdmin ? 'Gestion des utilisateurs' : 'Utilisateurs';
      }
      case UserRightCategory.vehicles: {
        return isAdmin ? 'Gestion des véhicules' : 'Véhicules';
      }
      case UserRightCategory.params: {
        return isAdmin ? 'Gestion des paramètres' : 'Paramètres';
      }
      case UserRightCategory.customers: {
        return isAdmin ? 'Gestion des clients' : 'Clients';
      }
      case UserRightCategory.customerSites: {
        if (userRoleType === UserRoleType.customer) {
          return 'Mes infos';
        }
        return isAdmin ? 'Gestion des sites clients' : 'Sites clients';
      }
      case UserRightCategory.preparers: {
        return isAdmin ? 'Gestion des préparateurs' : 'Préparateurs';
      }
      case UserRightCategory.structures: {
        return isAdmin ? 'Gestion des structures' : 'Structures';
      }
      case UserRightCategory.invoiceRequests: {
        return isAdmin ? 'Gestion des factures' : 'Factures';
      }
      case UserRightCategory.providerInvoiceOrders: {
        return isAdmin ? 'Gestion des bons de commande' : 'Bons de commande';
      }
      default: {
        return right;
      }
    }
  }
}
