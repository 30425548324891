import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {CustomerSiteFormComponent} from './customer-site-form/customer-site-form.component';
import {UntypedFormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';
import {CustomerSite} from 'src/app/shared/models/entities/customer-site';
import {CustomerSitesService} from 'src/app/shared/services/api/customer-sites.service';
import {EntityWrapperComponent} from '../../../shared/entity-wrapper/entity-wrapper.component';
import {UserRight} from 'src/app/shared/models/entities/user-right';
import {UserService} from 'src/app/shared/services/api/user.service';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'laveo-customer-site',
  templateUrl: './customer-site.component.html',
  styleUrls: ['./customer-site.component.scss']
})
export class CustomerSiteComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(EntityWrapperComponent) entityWrapperComponent: EntityWrapperComponent;
  @ViewChild(CustomerSiteFormComponent) customerSiteFormComponent: CustomerSiteFormComponent;

  isReady = false;
  customerSite: CustomerSite;
  editEnabled = false;
  deleteEnabled = false;
  readonly description = '';
  private subscriptions: Subscription[] = [];

  constructor(
    public readonly customerSitesService: CustomerSitesService,
    private readonly userService: UserService,
    private readonly titleService: Title,
  ) {}

  get title(): string {
    return this.customerSite?.name ?? 'Nouveau site client';
  }

  ngOnInit(): void {
    this.loadRole();
  }

  ngAfterViewInit(): void {
    this.subscriptions.push(this.entityWrapperComponent?.entity
      .subscribe(entity => {
        this.customerSite = entity as CustomerSite;
        if (this.customerSite !== undefined) {
          this.isReady = true;
        }
        this.setTitle();
      }));
  }

  ngOnDestroy(): void {
    for (const s of this.subscriptions) {
      s.unsubscribe();
    }
  }

  public form(): UntypedFormGroup {
    return this.customerSiteFormComponent?.form;
  }

  private loadRole(): void {
    const roleSubscription = this.userService.currentRole.subscribe(role => {
      this.editEnabled = role.rights.customerSites.includes(UserRight.update);
      this.deleteEnabled = role.rights.customerSites.includes(UserRight.delete);
    });

    if (roleSubscription) {
      this.subscriptions.push(roleSubscription);
    }
  }

  private setTitle(): void {
    this.titleService.setTitle(`Lavéo - ${this.title}`);
  }
}
