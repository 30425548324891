import { Observable, Observer } from 'rxjs';

export class Utils {
  public static groupBy<T>(array: T[], key: (element: T) => string): Record<string, T[]> {
    return array.reduce((grouped: Record<string, T[]>, element: T) => {
      (grouped[key(element)] = grouped[key(element)] || []).push(element);
      return grouped;
    }, {});
  }

  public static toDataURL(url: string): Observable<string> {
    const xhr = new XMLHttpRequest();

    return new Observable<string>((observer: Observer<string>) => {
      xhr.addEventListener('load', () => {
        const reader = new FileReader();
        reader.onloadend = () => {
          observer.next(reader.result as string);
          observer.complete();
        };

        reader.addEventListener('error', error => {
          observer.error(error);
          observer.complete();
        });

        reader.readAsDataURL(xhr.response);
      });

      xhr.open('GET', url);
      xhr.responseType = 'blob';
      xhr.send();
    });
  }

  public static dataURLtoFile(dataurl: string, filename: string): File {
    const array = dataurl.split(',');
    const mimeData = /:(.*?);/.exec(array[0]) ?? [];
    const mime = mimeData[1];
    const bstr = atob(array[1]);
    let n = bstr.length;
    const u8array = new Uint8Array(n);
    while (n--) {
      const c = bstr.codePointAt(n);
      if (c) {
        u8array[n] = c;
      }
    }

    return new File([u8array], filename, { type: mime });
  }

  public static capitalize(s: string): string {
    try {
      if (!s) {
        return s;
      }

      return s[0].toUpperCase() + s.slice(1).toLowerCase();
    } catch {
      return s;
    }
  }

  public static removeDiacritics(stringElement: string): string {
    if (!stringElement) {
      return '';
    }

    return stringElement.normalize('NFD').replaceAll(/[\u0300-\u036F]/g, '');
  }

  public static download(url: string, customFileName?: string): void {
    const fileName = customFileName ?? url.split('/').pop() ?? 'export';
    const a = document.createElement('a');
    a.href = customFileName ? `${url}?fileName=${encodeURIComponent(fileName)}` : url;
    a.download = fileName;
    document.body.append(a);
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }


  public static isBlank(string_: string | null | undefined): boolean {
    return string_ === null || string_ === undefined || string_.trim().length === 0;
  }
  public static isNotBlank(string_: string | null | undefined): boolean {
    return !this.isBlank(string_);
  }
}
