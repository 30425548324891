import { Directive, ElementRef, Output, EventEmitter, HostListener } from '@angular/core';

@Directive({
  selector: '[laveoClickOutside]'
})
export class ClickOutsideDirective {
  @Output() laveoClickOutside = new EventEmitter<void>();
  private listening = false;

  constructor(private elementReference: ElementRef) {}

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement) {
    if (!this.listening) {
      this.listening = true;
      return;
    }
    const clickedInside = this.elementReference.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.laveoClickOutside.emit();
    }
  }
}
