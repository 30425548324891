<nz-card [nzBordered]="false" [nzTitle]="cardTitle">
  <ng-template #cardTitle>
    <laveo-card-header
      [title]="category | rightName:currentRole?.type"
      [backButtonEnabled]="true"
      (backButtonClick)="goToAdmin()">
    </laveo-card-header>
  </ng-template>


  <div *ngIf="isAllowed">
    <nz-tabset>
      <nz-tab [nzTitle]="invoiceTitle" *ngIf="isCustomerOrCustomerSite || isAdmin">
        <ng-template #invoiceTitle>
          <i nz-icon nzType="euro"></i>
          Factures
        </ng-template>
        <ng-template nz-tab>
          <laveo-invoice-list></laveo-invoice-list>
        </ng-template>
      </nz-tab>

      <nz-tab [nzTitle]="providerInvoiceOrderTitle" *ngIf="isStructure || isAdmin">
        <ng-template #providerInvoiceOrderTitle>
          <i nz-icon nzType="euro"></i>
          Bons de commande / Auto-factures fournisseurs
        </ng-template>
        <ng-template nz-tab>
          <laveo-provider-invoice-order-list></laveo-provider-invoice-order-list>
        </ng-template>
      </nz-tab>

      <nz-tab [nzTitle]="manageTitle" *ngIf="isAdmin">
        <ng-template #manageTitle>
          <i nz-icon nzType="setting"></i>
          Gestion de la facturation
        </ng-template>
        <ng-template nz-tab>
          <laveo-admin-pricing></laveo-admin-pricing>
        </ng-template>
      </nz-tab>

      <nz-tab [nzTitle]="manageStats" *ngIf="isAdmin">
        <ng-template #manageStats>
          <i nz-icon nzType="bar-chart"></i>
          Tableau de bord
        </ng-template>
        <ng-template nz-tab>
          <laveo-statistics></laveo-statistics>
        </ng-template>
      </nz-tab>



    </nz-tabset>
  </div>
</nz-card>
