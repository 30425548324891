import {Component, OnDestroy, OnInit} from '@angular/core';

import {UserRight, UserRightCategory} from "../../../shared/models/entities/user-right";
import {UserRole, UserRoleType} from "../../../shared/models/entities/user-role";
import {Subscription} from "rxjs";
import {Router} from "@angular/router";
import {UserService} from "../../../shared/services/api/user.service";
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'laveo-provider-invoice-orders',
  templateUrl: './provider-invoice-orders.component.html',
  styleUrl: './provider-invoice-orders.component.scss'
})
export class ProviderInvoiceOrdersComponent implements OnInit, OnDestroy {
  category = UserRightCategory.providerInvoiceOrders;

  currentRole?: UserRole;  private subscriptions: Subscription[] = [];
  protected isAllowed: boolean;
  protected isAdmin: boolean;

  constructor(
    private readonly router: Router,
    private readonly userService: UserService,
    private readonly titleService: Title,
  ) {}


  ngOnInit(): void {
    this.setTitle();
    this.loadRole();
  }

  ngOnDestroy(): void {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }
  goToAdmin(): void {
    void this.router.navigate(['/admin']);
  }


  private loadRole(): void {
    const roleSubscription = this.userService.currentRole.subscribe(role => {
      this.currentRole = role;
      this.isAdmin = role.type === UserRoleType.admin;
      const userCanRead = role.rights.providerInvoiceOrders.includes(UserRight.read);
      const userCanEdit = role.rights.providerInvoiceOrders.includes(UserRight.update);
      const userCanDelete = role.rights.providerInvoiceOrders.includes(UserRight.delete);

      this.isAllowed = this.isAdmin || userCanRead || userCanEdit || userCanDelete;

    });

    if (roleSubscription) {
      this.subscriptions.push(roleSubscription);
    }
  }

  private setTitle(): void {
    this.titleService.setTitle('Lavéo - Gestion des bons de commande');
  }

}
