import { Deserializer, Type, Exclude } from 'typeserializer';
import { ServiceActionsNamePipe } from '../../pipes/service-actions-name.pipe';
import { Entity } from './entity';
import { Preparer } from './preparer';
import { Vehicle } from './vehicle';
import { Contact } from './contact';
import { ServiceActions } from './service-actions';
import { DateConverter } from '../../type-converters/date.converter';
import { isEqual } from 'date-fns';
import { CustomerSiteWorkflowType } from './customer-site-workflow-type';
import { ServiceHistory } from './service-history';
import { LaveoFile } from './file';
import { ServiceUpholsteryStainRemovalNamePipe } from '../../pipes/service-upholstery-stain-removal-name.pipe';
import { ServiceCarpetStainRemovalNamePipe } from '../../pipes/service-carpet-stain-removal-name.pipe';
import {InvoiceRequest} from "./invoice-request";

export class Service extends Entity {
  @Type(Date)
  @Deserializer(DateConverter.deserialize)
  date: Date;

  @Type(Date)
  @Deserializer(DateConverter.deserialize)
  dateAsked?: Date;

  @Type(Date)
  @Deserializer(DateConverter.deserialize)
  dateProposed?: Date;

  @Type(Date)
  @Deserializer(DateConverter.deserialize)
  datePlanned?: Date;

  @Type(Date)
  @Deserializer(DateConverter.deserialize)
  datePerformed?: Date;

  @Type(Date)
  @Deserializer(DateConverter.deserialize)
  dateCanceled?: Date;

  @Type(Date)
  @Deserializer(DateConverter.deserialize)
  currentDate: Date;

  @Type(Vehicle)
  vehicle: Vehicle;

  @Type(Preparer)
  preparer: Preparer;

  @Type([Contact])
  contacts: Contact[];

  @Exclude()
  workflowType: CustomerSiteWorkflowType;

  @Type(ServiceHistory)
  lastHistory?: ServiceHistory;

  /** L'état des lieux PDF */
  @Type(LaveoFile)
  vehicleStatePdf?: LaveoFile;

  reference: string;
  status: ServiceStatus;
  type: ServiceType;
  finishing: ServiceFinishing;
  comment: string;
  actions: ServiceActions[];
  fields: string[];
  hoursToInvoice?: number;
  firstIntervention: boolean;
  vehicleVeryDirty: boolean;
  upholsteryStainRemoval: ServiceUpholsteryStainRemoval[];
  carpetStainRemoval: ServiceCarpetStainRemoval[];
  conveying: boolean;
  sticking: boolean;
  desticking: boolean;
  contactZoneDisinfection: boolean;
  interiorDisinfection: boolean;
  vehicleStateEnabled: boolean;
  invoiced: boolean;
  invoiceLog: string;

  @Type(InvoiceRequest)
  invoiceRequest?: InvoiceRequest;

  get hasActions(): boolean {
    const filteredActions = this.actions?.filter(action => {
      const ignore = [ServiceActions.update, ServiceActions.cancel, ServiceActions.upload_vehicle_state];
      return !ignore.includes(action);
    });
    return filteredActions?.length > 0;
  }

  get actionsListTitle(): string | undefined {
    const pipe = new ServiceActionsNamePipe();
    const filteredActions = this.actions?.filter(action => {
      const ignore = [ServiceActions.update, ServiceActions.cancel, ServiceActions.upload_vehicle_state];
      return !ignore.includes(action);
    })?.map(action => pipe.transform(action));

    if (filteredActions?.length > 0) {
      const title = filteredActions?.length > 1 ? 'Actions à effectuer : ' : 'Action à effectuer : ';
      return title + filteredActions.join(' ou ');
    }

    return;
  }

  get dateAskedEqualProposed(): boolean {
    const dateAsked = new Date(this.dateAsked ?? 0);
    const dateProposed = new Date(this.dateProposed ?? 0);
    dateAsked.setSeconds(0, 0);
    dateProposed.setSeconds(0, 0);
    return isEqual(dateAsked, dateProposed);
  }

  get options(): string | undefined {
    const list: string[] = [];

    if (this.vehicleStateEnabled) {
      list.push('État des lieux');
    }

    if (this.firstIntervention) {
      list.push('1ère intervention');
    }

    if (this.vehicleVeryDirty) {
      list.push('Véhicule très sale');
    }

    if (this.conveying) {
      list.push('Convoyage');
    }

    if (this.sticking) {
      list.push('Stickage');
    }

    if (this.desticking) {
      list.push('Déstickage');
    }

    if (this.interiorDisinfection) {
      list.push('Désinfection habitacle');
    }

    if (this.contactZoneDisinfection) {
      list.push('Désinfection zone de contact');
    }

    if (this.upholsteryStainRemoval.length > 0) {
      const upholsteryStainNamePipe = new ServiceUpholsteryStainRemovalNamePipe();
      const names = this.upholsteryStainRemoval.map(stain => upholsteryStainNamePipe.transform(stain)).join(', ');
      list.push(`Détachage sellerie (${names})`);
    }

    if (this.carpetStainRemoval.length > 0) {
      const carpetStainNamePipe = new ServiceCarpetStainRemovalNamePipe();
      const names = this.carpetStainRemoval.map(stain => carpetStainNamePipe.transform(stain)).join(', ');
      list.push(`Détachage moquette (${names})`);
    }

    const formatter = new (Intl as any).ListFormat('fr', { style: 'long', type: 'conjunction' });
    if (list.length === 0) {
      return;
    }

    return formatter.format(list);
  }

  get vehicleStatePdfShortLink(): string | undefined {
    if (!this.vehicleStatePdf) {
      return;
    }

    return `${window.location.origin}/l/${this.vehicleStatePdf.id}`;
  }
}

export class ServiceArray {
  @Type([Service])
  children: Service[];
}

export enum ServiceStatus {
  created = 'CREATED',
  asked = 'ASKED',
  proposed = 'PROPOSED',
  planned = 'PLANNED',
  performed = 'PERFORMED',
  invoiced = 'INVOICED',
  invoiceToCheck = 'INVOICE_TO_CHECK',
  vehicleNotPresent = 'VEHICLE_NOT_PRESENT',
  canceled_by_customer_site = 'CANCELED_BY_CUSTOMER_SITE',
  canceled_by_structure = 'CANCELED_BY_STRUCTURE',
}

export enum ServiceType {
  interior = 'INTERIOR',
  exterior = 'EXTERIOR',
  full = 'FULL',
  no = 'NO'
}

export   enum ServiceFinishing {
  classic = 'CLASSIC',
  employee_discount = 'EMPLOYEE_DISCOUNT',
  restitution = 'RESTITUTION',
  fleet_management = 'FLEET_MANAGEMENT'
}

export enum ServiceUpholsteryStainRemoval {
  driver = 'DRIVER',
  passenger = 'PASSENGER',
  back_left = 'BACK_LEFT',
  back_right = 'BACK_RIGHT',
}

export enum ServiceCarpetStainRemoval {
  driver = 'DRIVER',
  passenger = 'PASSENGER',
  back_left = 'BACK_LEFT',
  back_right = 'BACK_RIGHT',
  boot = 'BOOT',
}
