import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { CardHeaderComponent } from './card-header/card-header.component';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { EntityWrapperComponent } from './entity-wrapper/entity-wrapper.component';
import {LaveoDividerComponent} from "./laveo-divider/laveo-divider.component";
import {NzIconModule} from "ng-zorro-antd/icon";
import {ProgressNotifierComponent} from "./progress-notifier/progress-notifier.component";
import {NzProgressComponent} from "ng-zorro-antd/progress";

@NgModule({
  declarations: [
    CardHeaderComponent,
    EntityWrapperComponent,
    LaveoDividerComponent,
    ProgressNotifierComponent,
  ],
  imports: [
    CommonModule,
    NzGridModule,
    NzCardModule,
    NzButtonModule,
    NzTagModule,
    NzSpinModule,
    NzSkeletonModule,
    NzIconModule,
    NzProgressComponent,
  ],
  exports: [
    CardHeaderComponent,
    EntityWrapperComponent,
    LaveoDividerComponent,
    ProgressNotifierComponent,
  ]
})
export class MainSharedModule {}
